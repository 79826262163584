/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 * For detailed information about this feature and limitations
 * take a look on SHARE_COMPONENT_README.md
 */

import React, {
    FunctionComponent, ReactNode, useState,
    
} from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Popper } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { QRCode } from 'react-qrcode-logo';
import Button from './Button';
import { ButtonVariant } from '../../types/general';
import { ReactComponent as ShareIcon } from '../../assets/images/share.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/images/whatsapp.svg';
import { ReactComponent as TelegramIcon } from '../../assets/images/telegram.svg';
import { ReactComponent as EmailIcon } from '../../assets/images/email_share.svg';
import { ReactComponent as QRCodeIcon } from '../../assets/images/qr-code.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/copy.svg';
import { ReactComponent as MoreOptionsIcon } from '../../assets/images/more-options.svg';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import Modal from './Modal';
import { Tooltip } from './Tooltip';
import { ReactComponent as UnlockitAvatar } from '../../assets/images/unlockit-avatar.svg';
import grayMiniLogo from '../../assets/images/mini-logo-U.svg';
import { LoadingCircles } from './LoadingCircles';

function encodeTextAndUrl(text: string, url: string) {
    return encodeURIComponent(`${text}\n\n${url}`);
}

export const whatsAppShareUrlBuilder = (text: string, url: string) => `https://wa.me/?text=${encodeTextAndUrl(text, url)}`;

export const telegramShareUrlBuilder = (text: string, url: string) => `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;

export const emailShareUrlBuilder = (text: string, url: string, subject: string, email: string) => `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeTextAndUrl(text, url)}`;

interface OwnProps extends TranslationContext {
    text: string;
    url: string;
    subject: string;
    buttonType?: ButtonVariant;
    customIcon?: ReactNode;
    email?: string;
    shareTitle?: string;
    copyButtonText?: string;
    copiedMlsTimer?: number;
    tooltipPosition?: 'top' | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' ;
    name?: string;
    isLoading?: boolean;
    handlerOnClick?: () => void;
    customTooltip?: string;
    externalOpen?: boolean;
    setExternalOpen?: (open: boolean) => void;
    
}
const ShareComponentBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        text,
        url,
        subject,
        customIcon,
        buttonType = ButtonVariant.Circle,
        email = '',
        shareTitle = t('shareComponent.shareTitle'),
        copyButtonText = t('shareComponent.copy'),
        copiedMlsTimer = 1000,
        tooltipPosition = 'top',
        name,
        isLoading,
        handlerOnClick,
        customTooltip,
        externalOpen,
        setExternalOpen,
       
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    
    const [isNativeShareOpen, setIsNativeShareOpen] = useState(false);
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

    const [copyAnchor, setCopyAnchor] = useState<null | HTMLElement>(null);
    const isCopyClicked = Boolean(copyAnchor);
    const id = isCopyClicked ? 'copied-popper' : undefined;

    const whatsAppShare = whatsAppShareUrlBuilder(text, url);
    const telegramShare = telegramShareUrlBuilder(text, url);
    const emailShare = emailShareUrlBuilder(text, url, subject ?? text, email);

    /**
     * Copy Invite link
     */
    const copyInvite = async (event: React.MouseEvent<HTMLElement>) => {
        const { currentTarget } = event;
        try {
            await navigator.clipboard.writeText(url);
            setCopyAnchor(currentTarget);
            setTimeout(() => {
                setCopyAnchor(null);
            }, copiedMlsTimer);
        } catch (error) {
            toast.error(t('errors.copyInvite'));
        }
    };
    
    /**
     * Native Share
     */
    const nativeShare = async () => {
        try {
            await navigator.share({ title: text, url });
            setIsNativeShareOpen((prev) => !prev);
        } catch (error: unknown) {
            handleNativeShareError(error);
        }
    };
    /**
     * Handle Native Share Errors
     *
     * For more details take a look on SHARE_COMPONENT_README.md
     *
     * @param error
     */
    const handleNativeShareError = (error: unknown) => {
        switch ((error as DOMException)?.name) {
            case 'AbortError':
            case 'InvalidStateError':
                setIsNativeShareOpen(false);
                break;
            default:
                toast.error(t('errors.nativeShare'));
                break;
        }
    };

    const verifyClick = () => {
        if (externalOpen !== undefined) {
            setExternalOpen?.(true);
            handlerOnClick?.();
        } else {
            setIsOpen(true);
        }
    };

    const verifyClose = () => {
        if (externalOpen !== undefined) {
            setExternalOpen?.(false);
        } else {
            setIsOpen(false);
        }
    };

    const renderShareButton = () => {
        switch (buttonType) {
            case ButtonVariant.Basic:
                return (
                    <Button
                        variant={buttonType}
                        extraClasses="share-component__btn"
                        startIcon={customIcon ?? <ShareIcon />}
                        onClick={verifyClick}
                        disabled={isOpen}
                        testId={`share-component${name ? `-${name}` : ''}`}
                    >
                        {t('shareComponent.shareTitle')}
                    </Button>
                );
            case ButtonVariant.IconBtn:
                return (
                    <Button
                        variant={buttonType}
                        extraClasses="share-component__btn primary"
                        onClick={verifyClick}
                        disabled={isOpen}
                        testId={`share-component${name ? `-${name}` : ''}`}
                    >
                        {customIcon ?? <ShareIcon />}
                    </Button>
                );
            case ButtonVariant.Circle:
            default:
                return (
                    <Button
                        variant={buttonType}
                        extraClasses="share-component__btn secondary"
                        onClick={verifyClick}
                        disabled={isOpen}
                        testId={`share-component${name ? `-${name}` : ''}`}
                    >
                        {customIcon ?? <ShareIcon />}
                    </Button>
                );
        }
    };

    return (
        <div className="share-component">
            <Tooltip title={customTooltip ?? shareTitle} placement={tooltipPosition}>
                <div>
                    {renderShareButton()}
                </div>
            </Tooltip>
            <Modal
                open={externalOpen ?? isOpen}
                title={shareTitle}
                handleClose={verifyClose}
                extraClasses="share-component__modal"
            >
                {isLoading !== undefined && isLoading ? (
                    <div className="loading">
                        <div className="loading-text">{t('signersOptions.EXTENDING_INVITATION')}</div>
                        <LoadingCircles testId="loading-circles" size="m" variant="primary" />
                    </div>
                ) : (
                    <>
                        <div className="share-component__modal__share-info">
                            <UnlockitAvatar />
                            <div className="share-component__modal__share-info__text">
                                <div className="share-component__modal__share-info__text__title">{text}</div>
                                <div className="share-component__modal__share-info__text__url">{url}</div>
                            </div>
                        </div>
                        <h4>{t('shareComponent.shareByApp')}</h4>
                        <div className="share-component__modal__share-options">
                            <Link to={whatsAppShare} target="_blank">
                                <Button
                                    variant={ButtonVariant.IconBtn}
                                    extraClasses="large basic-border-radius whatsapp"
                                    helperText={t('shareComponent.whatsApp')}
                                    testId="whatsapp-button"
                                >
                                    <WhatsappIcon />
                                </Button>
                            </Link>
                            <Link to={telegramShare} target="_blank">
                                <Button
                                    variant={ButtonVariant.IconBtn}
                                    extraClasses="large basic-border-radius telegram"
                                    helperText={t('shareComponent.telegram')}
                                    testId="telegram-button"
                                >
                                    <TelegramIcon />
                                </Button>
                            </Link>
                            <Link to={emailShare} target="_blank">
                                <Button
                                    variant={ButtonVariant.IconBtn}
                                    extraClasses="secondary large basic-border-radius email"
                                    helperText={t('shareComponent.email')}
                                    testId="email-button"
                                >
                                    <EmailIcon />
                                </Button>
                            </Link>
                            <div className="button__wrapper">
                                <Button
                                    variant={ButtonVariant.IconBtn}
                                    extraClasses="secondary large basic-border-radius QRCode"
                                    onClick={() => setIsQRCodeModalOpen(true)}
                                    helperText={t('shareComponent.QRcode')}
                                    testId="QRCode-button"
                                >
                                    <QRCodeIcon />
                                </Button>
                            </div>
                        </div>
                        <div className="share-component__modal__more-options">
                            <div className="share-component__modal__more-options__copy__wrapper">
                                <Button
                                    aria-describedby={id}
                                    onClick={copyInvite}
                                    variant={ButtonVariant.RectangularFilled}
                                    extraClasses="secondary full-width"
                                    endIcon={<CopyIcon />}
                                    testId="copy-button"
                                >
                                    {copyButtonText}
                                </Button>
                                <Popper
                                    className="custom-popper"
                                    id={id}
                                    open={isCopyClicked}
                                    anchorEl={copyAnchor}
                                >
                                    <div>
                                        {t('shareComponent.copied')}
                                    </div>
                                </Popper>
                            </div>
                            {!!navigator.share && (
                                <Button
                                    variant={ButtonVariant.RectangularFilled}
                                    extraClasses="secondary full-width"
                                    onClick={nativeShare}
                                    disabled={isNativeShareOpen}
                                    endIcon={<MoreOptionsIcon />}
                                    testId="native-share-button"
                                >
                                    {t('shareComponent.otherOptions')}
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </Modal>

            <Modal extraClasses="qrcode-invite__modal" open={isQRCodeModalOpen} title={t('shareComponent.QRcodeTitle')} handleClose={() => setIsQRCodeModalOpen(false)} testId="qrcode-invite__modal">
                <div className="qrcode-invite" data-testid="QRCode-container">
                    <QRCode
                        value={url}
                        size={200}
                        logoImage={grayMiniLogo}
                        logoWidth={45}
                        qrStyle="dots"
                        eyeRadius={5}
                        fgColor="#3a3b45"
                        ecLevel="L"
                    />
                </div>
            </Modal>
        </div>
    );
};

export const ShareComponent = withTranslationContext((ShareComponentBase));
