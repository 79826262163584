/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 *
 */

import posthog, { PostHogConfig } from 'posthog-js';
import React, { useEffect } from 'react';
import { ANALYTICS_ENABLED, POSTHOG_HOST, POSTHOG_KEY } from '../settings';
import { useAppSelector } from '../utils/storeHooks';

interface AnalyticsProps {
    children: React.ReactNode;
}

export const PosthogConfig: Partial<PostHogConfig> = {
    api_host: POSTHOG_HOST,
    persistence: 'memory',
    capture_exceptions: true,
    capture_heatmaps: true,
    capture_pageleave: true,
    capture_pageview: true,
    capture_performance: true,
    capture_dead_clicks: true,
};

export const AnalyticsWrapper: React.FC<AnalyticsProps> = (props) => {
    const { children } = props;
    const userSlice = useAppSelector((state) => state.user);

    useEffect(() => {
        if (userSlice.user?.id) {
            posthog.set_config({
                ...PosthogConfig,
                bootstrap: {
                    distinctID: userSlice.user?.id,
                },
            });
        }
    }, [userSlice.user?.id]);

    return children;
};

export const setupAnalytics = () => {
    if (ANALYTICS_ENABLED) {
        posthog.init(POSTHOG_KEY, PosthogConfig, 'analytics');
    }
};
