/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import Button from '../../elements/Button';
import { ReactComponent as BackIcon } from '../../../assets/images/chevron-left.svg';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import {
    DocumentChecklistAssignment, WorkflowAssignment,
} from '../../../types/workflows';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { buildUrl } from '../../../utils/navigation';
import { AppRoute } from '../../../constants/routes';
import { UploadDocumentCard } from '../../elements/UploadDocumentCard';
import { useAuthContext } from '../../controllers/AuthenticationContext';

type OwnProps = TranslationContext & WorkflowContext;

const WorkflowMissingDocumentsScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getWorkflowAssignments,
        uploadWorkflowDocument,
    } = props;
    const { user } = useAuthContext();
    
    const navigate = useNavigate();
    const { workflowId = '' } = useParams();

    const [documents, setDocuments] = useState<WorkflowAssignment[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        fetchWorkflowAssignments()
            .then(() => {
                setIsLoading(false);
            });
    }, []);

    const fetchWorkflowAssignments = async () => {
        const [documentList] = await getWorkflowAssignments(workflowId, DocumentChecklistAssignment.UPLOAD, true);

        if (documentList) {
            setDocuments(documentList.transactionAssignments);
        }
    };
    const handleUploadDocument = async (documentFile: File | undefined, id?: string | number) => {
        if (!documentFile) return;

        const partsOfTheId = String(id).split('-');
        if (partsOfTheId.length !== 2) return;

        const participantRole = partsOfTheId[0] || '';
        const documentTypeId = Number(partsOfTheId[1]) ?? 0;

        const document = documents.find((d) => d.participantRole === participantRole && d.documentTypeId === documentTypeId);

        if (!document) return;

        const response = await uploadWorkflowDocument(workflowId, {
            documentType: document.documentTypeId,
            file: documentFile,
            name: document.documentTypeName,
            notes: '',
            userId: user?.id === document.participantId ? undefined : document.participantId,
        });

        if (response[0]) {
            toast.success(t('workflows.view.uploadedDocumentSuccessMsg'));
            navigate(buildUrl(AppRoute.ViewWorkflowDocument, { workflowId, documentId: String(response[0].id) }));

            return;
        }

        toast.error(response[1].errors[0].getMessageTranslated(t));
    };
    return (
        <DefaultLayout>
            <div className="workflow-missing-documents-screen">
                <div className="workflow-missing-documents-screen__header">
                    <div className="workflow-missing-documents-screen__header__back-and-title">
                        <Button
                            extraClasses="circular-ghost-btn"
                            onClick={() => navigate(buildUrl(AppRoute.ViewWorkflow, { workflowId }))}
                            testId="back-btn"
                        >
                            <BackIcon />
                        </Button>
                        <h1 className="workflow-missing-documents-screen__back-and-title__title">
                            {t('workflows.view.missingDocuments')}
                        </h1>
                    </div>
                </div>
                {isLoading && <LoadingCircles size="s" variant="primary" />}
                {!isLoading && documents.length === 0 && (<p>{t('workflows.view.noDocumentsFound')}</p>)}
                {!isLoading && documents.length > 0 && (
                    <div className="upload-document-card-list">
                        {documents.map((d) => (
                            <UploadDocumentCard
                                key={`${d.participantRole}-${d.documentTypeId}`}
                                document={{
                                    id: `${d.participantRole}-${d.documentTypeId}`,
                                    title: d.documentTypeCode ? t(`workflowDocumentTypes.${d.documentTypeCode}`) : d.documentTypeName,
                                    description: `(${t(`participantRole.${d.participantRole}`)})`,
                                }}
                                onUploadFileCallback={handleUploadDocument}
                            />
                        ))}
                    </div>
                )}
            </div>
        </DefaultLayout>
    );
};

export const WorkflowMissingDocumentsScreen = withWorkflowContext(withTranslationContext(WorkflowMissingDocumentsScreenComponent));
