/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    useMemo,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { ButtonVariant, PendingInvite } from '../../types/general';
import { Button } from './Button';
import { ReactComponent as CloseIcon } from '../../assets/images/closeBtn.svg';
import { LoadingCircles } from './LoadingCircles';
import InfiniteScrollWrapper from './InfiniteScrollWrapper';
import { Tooltip } from './Tooltip';
import { buildUrl } from '../../utils/navigation';
import { AppRoute } from '../../constants/routes';
import { MembersContext, withMembersContext } from '../controllers/MembersContext';
import { InviteTypeEnum } from '../../types/user';

interface OwnProps extends TranslationContext, MembersContext {
    pendingInvites: PendingInvite[];
    isLoading: boolean;
    hasMore: boolean;
    requestMore(): Promise<void>;
    totalInvites?: number;
    moreElementPerScroll?: boolean;
}

const PendingInvitesListBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        pendingInvites,
        isLoading,
        hasMore,
        requestMore,
        totalInvites,
        moreElementPerScroll = false,
        acceptInvite,
        rejectInvite,
    } = props;

    const navigate = useNavigate();
    const [elementsToHide, setElementsToHide] = useState<string[]>([]);

    const invitesUpdatedNumber = useMemo(() => {
        return totalInvites ? totalInvites - elementsToHide.length : '';
    }, [totalInvites, elementsToHide]);
    
    const renderTextByInviteType = (invite: PendingInvite) => {
        switch (invite.inviteType) {
            case InviteTypeEnum.ORGANIZATION:
                return t('pendingInvites.member', { title: invite.resourceTitle });
            case InviteTypeEnum.CONTRACT:
                return t('pendingInvites.signer', { title: invite.resourceTitle, type: invite.resourceType ?? '' });
            case InviteTypeEnum.TRANSACTION:
                return t('pendingInvites.participant', { title: invite.resourceTitle, type: invite.resourceType ? t(`createWorkflowSelectType.${invite.resourceType}`) : '' });
            default:
                return '';
        }
    };
                    
    const handleAcceptMemberInvite = async (invite: PendingInvite) => {
        const acceptError = await acceptInvite(invite.inviteToken);
   
        if (acceptError) {
            onAcceptInviteFailure(acceptError.errors[0].getMessageTranslated(t));
        } else {
            onAcceptInviteSuccess();
        }
    };
    
    const onAcceptInviteSuccess = () => {
        toast.success(t('invite.acceptSuccess'));
        navigate(AppRoute.Index);
    };
    
    const onAcceptInviteFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };
   
    const handleDeclineMembernvite = async (invite: PendingInvite) => {
        const rejectError = await rejectInvite(invite.inviteToken);
   
        if (rejectError) {
            onRejectInviteFailure(rejectError.errors[0].getMessageTranslated(t));
        } else {
            onRejectInviteSuccess();
        }
    };

    const onRejectInviteSuccess = () => {
        toast.success(t('invite.rejectSuccess'));
        navigate(AppRoute.Index);
    };

    const onRejectInviteFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    const handleCloseUntilNextRefresh = (inviteId: string) => {
        setElementsToHide((prev) => [...prev, inviteId]);
    };

    const renderButtonsByInviteType = (invite: PendingInvite) => {
        switch (invite.inviteType) {
            case InviteTypeEnum.ORGANIZATION:
                return (
                    <>
                        <span>
                            <Button
                                onClick={() => handleDeclineMembernvite(invite)}
                                testId="reject-btn"
                            >
                                {t('pendingInvites.buttons.reject')}
                            </Button>
                            <Button
                                extraClasses="small-btn"
                                variant={ButtonVariant.Curved}
                                onClick={() => handleAcceptMemberInvite(invite)}
                                testId="accept-btn"
                            >
                                {t('pendingInvites.buttons.accept')}
                            </Button>
                        </span>
                        <Tooltip title={t('pendingInvites.dismiss')} placement="top">
                            <div>
                                <Button
                                    extraClasses="secondary"
                                    onClick={() => handleCloseUntilNextRefresh(invite.id)}
                                    testId="dismiss-btn"
                                >
                                    <CloseIcon />
                                </Button>
                            </div>
                        </Tooltip>
                    </>
                );
            case InviteTypeEnum.CONTRACT:
                return (
                    <>
                        <Button
                            extraClasses="small-btn"
                            variant={ButtonVariant.Curved}
                            onClick={() => navigate(buildUrl(AppRoute.SignerInvitationClaimCallback, { signerToken: invite.inviteToken }))}
                            testId="sign-btn"
                        >{t('pendingInvites.buttons.sign')}
                        </Button>
                        <Tooltip title={t('pendingInvites.dismiss')} placement="top">
                            <div>
                                <Button
                                    extraClasses="secondary"
                                    onClick={() => handleCloseUntilNextRefresh(invite.id)}
                                    testId="dismiss-btn"
                                >
                                    <CloseIcon />
                                </Button>
                            </div>
                        </Tooltip>
                    </>
                );
            case InviteTypeEnum.TRANSACTION:
                return (
                    <>
                        <Button
                            extraClasses="small-btn"
                            variant={ButtonVariant.Curved}
                            onClick={() => navigate(buildUrl(AppRoute.WorkflowParticipantAssign, { participantToken: invite.inviteToken }))}
                            testId="participate-btn"
                        >{t('pendingInvites.buttons.participate')}
                        </Button>
                        <Tooltip title={t('pendingInvites.dismiss')} placement="top">
                            <div>
                                <Button
                                    extraClasses="secondary"
                                    onClick={() => handleCloseUntilNextRefresh(invite.id)}
                                    testId="dismiss-btn"
                                >
                                    <CloseIcon />
                                </Button>
                            </div>
                        </Tooltip>
                    </>
                );
            default:
                return '';
        }
    };
    return (
        <>
            {(pendingInvites.length - elementsToHide.length) > 0 && (
                <div
                    className={classNames(
                        'pending-invites',
                        { hasMore: !!hasMore },
                    )}
                    data-testid="pending-invites"
                >
                    <div className="pending-invites__header">
                        <h2>{t('pendingInvites.title')}</h2>
                        <p>{t('pendingInvites.description', { totalInvites: invitesUpdatedNumber ?? '' })}</p>
                    </div>
                    <div className={classNames('pending-invites__list', {
                        'more-elements-per-scroll': moreElementPerScroll,
                    })}
                    >
                        {isLoading && <LoadingCircles size="s" variant="secondary" />}
                        <InfiniteScrollWrapper
                            hasMore={hasMore}
                            requestMore={requestMore}
                        >
                            {pendingInvites?.filter((invite) => !elementsToHide.includes(invite.id))
                                .map((invite) => {
                                    return (
                                        <div key={invite.inviteToken} className="pending-invites__list__item" data-testid="pending-invite-list-item">
                                            <div className="pending-invites__list__item__text">
                                                {renderTextByInviteType(invite)}
                                            </div>
                                            <div className="pending-invites__list__item__buttons">
                                                {renderButtonsByInviteType(invite)}
                                            </div>
                                        </div>
                                    );
                                })}
                        </InfiniteScrollWrapper>
                    </div>
                </div>
            )}
        </>
    );
};
 
export const PendingInvitesList = withTranslationContext(withMembersContext(PendingInvitesListBase));
