/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import Button from '../../elements/Button';
import { ReactComponent as BackIcon } from '../../../assets/images/chevron-left.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import { WorkflowDocument, WorkflowDocumentType } from '../../../types/workflows';
import { ButtonVariant } from '../../../types/general';
import { LoadingCircles } from '../../elements/LoadingCircles';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { DocumentCard } from '../../elements/documents/DocumentCard';
import { AddDocumentModal } from '../../elements/documents/AddDocumentModal';
import { buildUrl } from '../../../utils/navigation';
import { AppRoute } from '../../../constants/routes';

type OwnProps = TranslationContext & WorkflowContext;

const WorkflowDocumentsScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getDocumentTypes,
        getDocuments,
        uploadWorkflowDocument,
    } = props;

    const { workflowId = '' } = useParams();
    const navigate = useNavigate();

    const [documents, setDocuments] = useState<WorkflowDocument[]>([]);
    const [documentTypes, setDocumentTypes] = useState<WorkflowDocumentType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalAddingNewDocumentOpen, setIsModalAddingNewDocumentOpen] = useState(false);
    const [cursor, setCursor] = useState<string>('');
    const [totalAmount, setTotalAmount] = useState<number>();

    useEffect(() => {
        setIsLoading(true);

        Promise.allSettled([fetchDocuments(), fetchDocumentTypes()]).then(() => {
            setIsLoading(false);
        });
    }, []);

    const fetchDocuments = async () => {
        const [documentList] = await getDocuments(workflowId, cursor);

        if (documentList) {
            setDocuments([...documents, ...documentList.results]);
            setTotalAmount(documentList.totalAmount);
            setCursor(documentList.cursor);
        }
    };

    const fetchDocumentTypes = async () => {
        const [documentTypeList] = await getDocumentTypes(workflowId);

        if (documentTypeList) {
            setDocumentTypes(documentTypeList);
        }
    };

    const handleSubmitNewDocument = (file: File, notes?: string) => {
        const documentType = documentTypes.find((d) => d.code === 'OTHER');
        if (!documentType) {
            toast.error(t('errors.cantUploadDocument'));
            return;
        }

        uploadWorkflowDocument(workflowId, {
            documentType: documentType.id,
            file,
            notes,
            name: file.name,
        }).then((response) => {
            if (response[1]) {
                toast.error(response[1].errors[0].getMessageTranslated(t));
            } else {
                toast.success(t('workflows.view.uploadedDocumentSuccessMsg'));
                navigate(buildUrl(AppRoute.ViewWorkflowDocument, { workflowId, documentId: String(response[0].id) }));
            }
        });
    };

    return (
        <DefaultLayout>
            <div className="workflow-documents-screen">
                <div className="workflow-documents-screen__header">
                    <div className="workflow-documents-screen__header__back-and-title">
                        <Button
                            extraClasses="circular-ghost-btn"
                            onClick={() => navigate(buildUrl(AppRoute.ViewWorkflow, { workflowId }))}
                            testId="back-btn"
                        >
                            <BackIcon />
                        </Button>
                        <h1 className="workflow-documents-screen__back-and-title__title">
                            {t('workflows.view.uploadedDocuments')}
                            <span className="count" data-testid="documents-count" />
                        </h1>
                    </div>
                    <Button
                        variant={ButtonVariant.Circle}
                        extraClasses="primary"
                        testId="add-document-btn"
                        onClick={() => setIsModalAddingNewDocumentOpen(true)}
                    >
                        <PlusIcon />
                    </Button>
                </div>
                {isLoading && <LoadingCircles size="s" variant="primary" />}
                {!isLoading && totalAmount === 0 && (<p>{t('workflows.view.noDocumentsFound')}</p>)}
                {!isLoading && documents.length > 0 && (
                    <InfiniteScrollWrapper
                        hasMore={!!cursor}
                        requestMore={fetchDocuments}
                        extraClasses="document-card-list"
                    >
                        {documents.map((d) => (
                            <DocumentCard
                                key={d.id}
                                document={d}
                            />
                        ))}
                    </InfiniteScrollWrapper>
                )}
            </div>
            <AddDocumentModal
                open={isModalAddingNewDocumentOpen}
                onModalClose={() => setIsModalAddingNewDocumentOpen(false)}
                onSubmit={handleSubmitNewDocument}
            />
        </DefaultLayout>
    );
};

export const WorkflowDocumentsScreen = withWorkflowContext(withTranslationContext(WorkflowDocumentsScreenComponent));
