/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Stack } from '@mui/material';
import {
    FunctionComponent,
    Suspense,
    lazy,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
import { ReactComponent as BackIcon } from '../../../assets/images/chevron-left.svg';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { ErrorResponse } from '../../../types/errors';
import { BannerType } from '../../../types/general';
import {
    ParticipantPermissionsList, ResourcePermissionsType, ResourceType, Workflow, WorkflowDocument,
} from '../../../types/workflows';
import { verifyParticipantPermission } from '../../../utils/participantPermission';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import { Banner } from '../../elements/Banner';
import Button from '../../elements/Button';
import { Drawer } from '../../elements/Drawer';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { DownloadOption } from '../../elements/contracts/options/DownloadOption';
import { DeleteDocumentOption } from '../../elements/documents/options/DeleteDocumentOption';
import { ReviewDocumentOption } from '../../elements/documents/options/ReviewDocumentOption';
import { WorkflowDocumentPermissionsOption } from '../../elements/documents/options/WorkflowDocumentPermissionsOption';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { ParticipantPermissionGuard } from '../../elements/participants/ParticipantPermissionGuard';
import { LoadingScreen } from '../LoadingScreen';
import { UnauthorizedAssetScreen } from './UnauthorizedAssetScreen';
import { AppRoute } from '../../../constants/routes';
import { buildUrl } from '../../../utils/navigation';

const PdfViewerWrapper = lazy(() => import('../../elements/pdfViewer/PdfViewerWrapper'));

type OwnProps = TranslationContext & WorkflowContext;

const ViewWorkflowDocumentScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getDocument,
        getDocumentPdf,
        getWorkflowParticipantPermissions,
        getWorkflow,
    } = props;
    const navigate = useNavigate();

    const { workflowId = '', documentId = '' } = useParams();

    const pdfPagesListRef = useRef<VariableSizeList>();

    const [participantPermissions, setParticipantPermissions] = useState<ParticipantPermissionsList | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [document, setDocument] = useState<WorkflowDocument | null>(null);
    const [workflow, setWorkflow] = useState<Workflow | null>(null);
    const [documentPdfUrl, setDocumentPdfUrl] = useState<string | null>(null);
    const [error, setError] = useState<ErrorResponse | null>(null);
    const [hasPermissionToAccessDocument, setHasPermissionToAccessDocument] = useState(true);
    const [isBannerHidden, setIsBannerHidden] = useState<boolean>(false);
    const [isNotesDrawerOpen, setIsNotesDrawerOpen] = useState<boolean>(false);
    const userCanReviewDocument = useUserHasPermission([
        'REVIEW_ORGANIZATION_TRANSACTIONS_DOCUMENTS', 'REVIEW_ALL_ORGANIZATION_TRANSACTIONS_DOCUMENTS',
    ]);

    useEffect(() => {
        if (error) throw error;
    }, [error]);

    useEffect(() => {
        setIsLoading(true);
        getWorkflow(workflowId).then((response) => {
            const [workflowResponse, workflowError] = response;

            if (workflowResponse) {
                setWorkflow(workflowResponse);
            }

            if (workflowError) {
                setIsLoading(false);
                setError(workflowError);
            }
        });
        getWorkflowParticipantPermissions(workflowId).then((permissionsResponse) => {
            const [permissions, responseError] = permissionsResponse;

            if (!permissions) {
                setError(responseError);
                return;
            }

            const hasPermission = permissions.documents.find((d) => d.id === Number(documentId) && !d.permissions.includes(ResourcePermissionsType.NONE));

            if (!hasPermission) {
                setHasPermissionToAccessDocument(false);
                setIsLoading(false);
                return;
            }

            setParticipantPermissions(permissions);

            getDocument(workflowId, documentId).then((response) => {
                const [documentResponse, documentError] = response;

                if (documentResponse) {
                    setDocument(documentResponse);
                }

                if (documentError) {
                    setIsLoading(false);
                    setError(documentError);
                }
            });
        });
    }, []);

    useEffect(() => {
        if (!document) return;

        getDocumentPdf(workflowId, documentId).then((response) => {
            const [fileResponse, responseError] = response;
            if (responseError) {
                setError(responseError);
                return;
            }

            setIsLoading(false);

            if (fileResponse) {
                setDocumentPdfUrl(URL.createObjectURL(fileResponse));
            }
        });
    }, [document]);

    const renderBannerNotesAndModal = () => {
        const documentNotes = document?.documentNotes ?? '';

        return (
            <div className="banner-document-notes">
                <p>{documentNotes.length > 30 ? `${documentNotes.slice(0, 30)}...` : documentNotes}</p>
                <button
                    type="button"
                    onClick={() => setIsNotesDrawerOpen(true)}
                    data-testid="notes-see-more"
                >
                    {t('workflows.documents.seeMore')}
                </button>
            </div>
        );
    };

    return (
        <>
            <DefaultLayout>
                {hasPermissionToAccessDocument
                    ? (
                        <div className="workflow-documents-screen">
                            {document?.documentNotes && !isBannerHidden && (
                                <Banner type={BannerType.INFO} title={t(`workflows.status.${document.documentStatus}`, { name: document.lastUserInteractionName })} onClose={() => setIsBannerHidden(true)}>
                                    {renderBannerNotesAndModal()}
                                </Banner>
                            )}
                            <div className="workflow-documents-screen__header">
                                <div className="workflow-documents-screen__header__back-and-title">
                                    <Button
                                        extraClasses="circular-ghost-btn"
                                        onClick={() => navigate(buildUrl(AppRoute.WorkflowDocuments, { workflowId }))}
                                        testId="back-btn"
                                    >
                                        <BackIcon />
                                    </Button>
                                </div>
                            </div>
                            {isLoading && <LoadingCircles size="s" variant="primary" />}
                            {!isLoading && workflow && document && documentPdfUrl && (
                                <Suspense fallback={<LoadingScreen />}>
                                    <div className="contract-screen__pdf-viewer pdf-viewer-wrap">
                                        <PdfViewerWrapper
                                            fileUrl={documentPdfUrl}
                                            pdfPagesListRef={pdfPagesListRef}
                                        />
                                        <div className="contract-screen__pdf-viewer__buttons">
                                            <Stack
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="flex-end"
                                                spacing={0.5}
                                            >
                                                {(participantPermissions && verifyParticipantPermission(participantPermissions, [ResourcePermissionsType.REVIEW, ResourcePermissionsType.MANAGE], ResourceType.DOCUMENT, Number(documentId))) || userCanReviewDocument ? (
                                                    <ReviewDocumentOption document={document} />
                                                ) : null}
                                                <ParticipantPermissionGuard
                                                    participantPermissions={participantPermissions}
                                                    allowedPermissions={[ResourcePermissionsType.REVIEW, ResourcePermissionsType.MANAGE]}
                                                    resourceType={ResourceType.DOCUMENT}
                                                    resourceId={Number(documentId)}
                                                >
                                                    <WorkflowDocumentPermissionsOption document={document} testId="review-btn" />
                                                </ParticipantPermissionGuard>
                                                <DownloadOption
                                                    fileUrl={documentPdfUrl}
                                                    fileName={document.name}
                                                    testId="download-button"
                                                />
                                                <ParticipantPermissionGuard
                                                    participantPermissions={participantPermissions}
                                                    allowedPermissions={[ResourcePermissionsType.MANAGE]}
                                                    resourceType={ResourceType.DOCUMENT}
                                                    resourceId={Number(documentId)}
                                                >
                                                    <DeleteDocumentOption
                                                        document={document}
                                                        workflow={workflow}
                                                    />
                                                </ParticipantPermissionGuard>
                                            </Stack>
                                        </div>
                                    </div>
                                </Suspense>
                            )}
                        </div>
                    ) : <UnauthorizedAssetScreen workflowId={Number(workflowId)} resourceId={Number(documentId)} resourceType={ResourceType.DOCUMENT} />
                }
            </DefaultLayout>
            <Drawer
                handleClose={() => setIsNotesDrawerOpen(false)}
                open={isNotesDrawerOpen}
                title={t('workflows.documents.notes')}
                testId="notes-drawer"
                extraClasses="notes-drawer"
            >
                {document?.documentNotes}
            </Drawer>
        </>
    );
};

export const ViewWorkflowDocumentScreen = withWorkflowContext(withTranslationContext(ViewWorkflowDocumentScreenComponent));
