/*
 *
 * @Copyright 2023 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ReactNode } from 'react';
import { WorkflowStateEnumType } from './workflows';
import { InviteTypeEnum } from './user';

export interface KeyedObject<T> {
    [key: string]: T;
}

export interface OptionSelectField<T> {
    value: T;
    label: string;
    icon?: ReactNode;
}
export interface OptionRadioField<T> {
    value: T;
    label: string;
    icon?: ReactNode;
    disabled?: boolean;
    disableMessage?: string;
    extraClasses?: string;
}

export interface OptionCheckboxGroupField {
    id: number | string;
    value: boolean;
    label: string;
    disabled?: boolean;
    extraClasses?: string;
}

export interface ListResponse<A> {
    cursor: string;
    results: A[];
    totalAmount?: number;
}

export type ValueOf<T> = T[keyof T];

export enum ButtonVariant {
    Basic = 'Basic',
    Curved = 'Curved',
    IconBtn = 'IconBtn',
    RectangularFilled = 'RectangularFilled',
    RectangularStroked = 'RectangularStroked',
    Circle = 'Circle'
}
export enum FormFieldType {
    Text = 'Text',
    Number = 'Number',
    Email = 'Email',
    Password = 'Password',
}

export const isOfType = <T, F = unknown>(v: T | F, uniqueKey: keyof T): v is T => {
    return Boolean((v as T)[uniqueKey]);
};

export interface ElementPosition {
    left: number;
    top: number;
}

export enum BannerType {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS'
}

export interface DatePickerQuickButtons {
    key: number;
    value: string;
}

export interface MenuListOptions {
    value: string;
    action(): void;
    testId?: string;
    disabled?: boolean;
}

export interface ProgressStep {
    value: WorkflowStateEnumType;
    label: string;
}

export interface CardObject {
    id?: string | number;
    title: string;
    description?: string;
    icon?: ReactNode;
}

export enum OrderOptions {
    ASCENDING = 'Ascending',
    DESCENDING = 'Descending'
}
export const TOTAL_ITEMS = 'x-total-count';

export interface Address {
    addressLine1: string;
    addressLine2?: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
}

export interface ContactablePerson {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface FiscalInfo {
    fiscalNumber: string;
    fiscalName: string;
}

export interface NoContentResponse {
    status: number;
}

export interface PendingInvite {
    id: string;
    inviteType: InviteTypeEnum;
    inviteToken: string;
    inviteExpiresAt: string;
    resourceTitle: string;
    resourceType: string | null;

}
