/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import Avatar from '@mui/material/Avatar';
import { FunctionComponent, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/images/logo_without_text.svg';
import avatarPlaceholder from '../../assets/images/organization_avatar_placeholder.png';
import { ReactComponent as UserIcon } from '../../assets/images/user.svg';
import { OrganizationsContext, withOrganizationsContext } from '../controllers/OrganizationsContext';
import { LanguageAndCurrencyPreferences } from './LanguageAndCurrencyPreferences';
import { UserProfileSideBar } from './UserProfileSideBar';
import { HelpNavbarElement } from './help/HelpNavbarElement';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { Tooltip } from './Tooltip';

interface OwnProps extends OrganizationsContext, TranslationContext { }

const NavBarComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        organizationSelected,
    } = props;

    const [isOpenOrgMenu, setIsOpenOrgMenu] = useState(false);
    
    return (
        <header className="nav-bar-wrap" data-testid="nav-bar">
            <div className="nav-bar">
                <div className="nav-bar__inner">
                    <div className="nav-bar__inner__logo-wrap">
                        <Logo />
                    </div>
                </div>
                <div className="navigation-wrap">
                    <ul>
                        <li>
                            <LanguageAndCurrencyPreferences />
                        </li>
                        <li>
                            <HelpNavbarElement />
                        </li>
                       
                        <li className="organization-item">
                            <button onClick={() => setIsOpenOrgMenu(!isOpenOrgMenu)} type="button">
                                <Avatar alt="user avatar">
                                    <Tooltip title={t('userProfile.avatarTooltip')} placement="bottom">
                                        <UserIcon />
                                    </Tooltip>
                                </Avatar>
                                <Avatar
                                    alt={`${organizationSelected?.organization.name} avatar`}
                                    src={organizationSelected?.organization?.logoUrl ?? avatarPlaceholder}
                                    className="organization-avatar"
                                />
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <UserProfileSideBar
                isOpen={isOpenOrgMenu}
                onClose={() => setIsOpenOrgMenu(false)}
            />
        </header>
    );
};

export const NavBar = withTranslationContext(withOrganizationsContext(NavBarComponent));
