/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Money, NullableMoney } from './billing';
import { Contract } from './contracts';
import { PermissionList } from './permissions';
import { PublicUserDTO, User } from './user';

export enum WorkflowType {
    SELL = 'SELL',
    RENTAL = 'RENTAL',
    EXCHANGE = 'EXCHANGE',
}

export enum WorkflowFrequencyType {
    NONE = 'NONE',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export enum CommissionType {
    FIXED = 'FIXED',
    PERCENTAGE = 'PERCENTAGE',
    RENT = 'RENT',
    NONE = 'NONE',
}

export interface WorkflowResponseType {
    transactionType: WorkflowType;
    description: string;
    commissionTypesAllowed: CommissionType[];
    frequencyTypes: WorkflowFrequencyType[];
}

export interface WorkflowPayload {
    title: string;
    description: string;
    transactionType: WorkflowType;
    commissionType: CommissionType;
    commission: NullableMoney;
    value: NullableMoney;
    frequencyType: WorkflowFrequencyType;
    cmi?: string;
    expiresAt: string;
    externalId: string;
    walletForPaymentId?: number;
}

export interface Workflow {
    id: number;
    createdDate: string;
    lastModifiedDate: string | null;
    title: string;
    description: string;
    transactionType: WorkflowType;
    commissionType: CommissionType;
    commission: Money;
    value: Money;
    frequencyType: WorkflowFrequencyType;
    status: WorkflowStateEnumType;
    expiresAt: string;
    externalId: string;
    userOwnerId: string;
    organizationId: number;
    propertyId: number | null;
    cmi: string | null;
    property: WorkflowProperty | null;
}

export enum SellWorkflowStep {
    GENERAL_INFO = 'GENERAL_INFO',
    PROPERTY = 'PROPERTY',
    PARTICIPANTS = 'PARTICIPANTS',
}

export interface CurrencyInfo {
    symbol: string;
    name: string;
    symbol_native: string;
    decimal_digits: number;
    rounding: number;
    code: string;
    name_plural: string;
}

export enum PropertyTypeEnum {
    APARTMENT = 'APARTMENT',
    WAREHOUSE = 'WAREHOUSE',
    OFFICE = 'OFFICE',
    GARAGE = 'GARAGE',
    STORE = 'STORE',
    DWELLING_SINGLE_FAMILY = 'DWELLING_SINGLE_FAMILY',
    DWELLING_MULTI_FAMILY='DWELLING_MULTI_FAMILY',
    DWELLING_TOWNHOUSE='DWELLING_TOWNHOUSE',
    BUILDING = 'BUILDING',
    FARM = 'FARM',
    LAND = 'LAND',
}
export enum PropertyTypologyEnum {
    T0 = 'T0',
    T1 = 'T1',
    T2 = 'T2',
    T3 = 'T3',
    T4 = 'T4',
    T5 = 'T5',
    T6 = 'T6',
    T7 = 'T7',
    T8PLUS = 'T8PLUS',
}
export interface PropertyType {
    propertyType: PropertyTypeEnum;
    description: string;
}

export interface PropertyTypology {
    typology: PropertyTypologyEnum;
    description: string;
}

export enum MatrixArticleType {
    URBAN = 'U',
    RUSTIC = 'R',
}
export interface WorkflowPropertyPayload {
    propertyType?: PropertyTypeEnum;
    typology?: PropertyTypologyEnum;
    address: string;
    district: number | null;
    county: number | null;
    parish: number | null;
    zone: string;
    matrixType: MatrixArticleType;
    matrixArticle: string;
    matrixFraction: string;
}
export interface WorkflowProperty {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    propertyType: PropertyTypeEnum;
    typology: PropertyTypologyEnum;
    address: string;
    district: LocationEntity | null;
    county: LocationEntity | null;
    parish: LocationEntity | null;
    zone: string;
    matrixType: MatrixArticleType;
    matrixArticle: string;
    matrixFraction: string;
    uniqueId: string;
}

export interface LocationEntity {
    id: number;
    createdDate?: string;
    lastModifiedDate?: string;
    name: string;
    code?: string;
}
export interface LocationPayload {
    l1Id?: number;
    l2Id?: number;
}
export interface Participant {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    inviteToken: string | null;
    transactionId: number;
    inviteCreatorId: string;
    userId?: string;
    inviteEmail: string;
    inviteExpiresAt: string;
    name: string;
    participantRole: WorkflowParticipantRoleEnum;
}

export interface ParticipantInvite {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    inviteToken: string | null;
    transaction: Workflow | null;
    userId: string | null;
    inviteCreatorId: string;
    inviteEmail: string;
    inviteExpiresAt: string;
    name: string;
    participantRole: WorkflowParticipantRoleEnum;
}

export interface ParticipantPermissionsList {
    participant: Participant;
    organizationPermissions: PermissionList;
    transactionPermissions: ResourcePermissionsType[];
    documents: ResourcePermission[];
    contracts: ResourcePermission[];
}
export interface ParticipantPermissions {
    role: WorkflowParticipantRoleEnum;
    transaction: PermissionList;
    documents: PermissionList;
    contracts: PermissionList;
}
export interface ParticipantPermissionsPayload {
    transaction?: PermissionList;
    documents?: PermissionList;
    contracts?: PermissionList;
}

export interface ParticipantPayload {
    email: string;
    name: string;
    participantRole: WorkflowParticipantRoleEnum;
}
export interface ParticipantMemberPayload {
    userId: string;
    participantRole: WorkflowParticipantRoleEnum;
}

export interface WorkflowParticipantRole {
    participantRole: WorkflowParticipantRoleEnum;
    description: string;
    permissions: PermissionList;
}
export enum WorkflowParticipantRoleEnum {
    TRANSACTION_OWNER = 'TRANSACTION_OWNER',
    ADMIN = 'ADMIN',
    VIEWER = 'VIEWER',
    PROPERTY_OWNER = 'PROPERTY_OWNER',
    BUYER = 'BUYER',
    TENANT = 'TENANT',
    LANDLORD = 'LANDLORD',
    GUARANTOR = 'GUARANTOR',
    LAWYER = 'LAWYER',
    PARTNER_CONSULTANT = 'PARTNER_CONSULTANT',
    SOLICITOR = 'SOLICITOR',
    INHERITOR = 'INHERITOR'
}
export enum SellWorkflowStateEnum {
    SELL_RAISING = 'SELL_RAISING',
    SELL_DOCUMENTS_APPROVAL = 'SELL_DOCUMENTS_APPROVAL',
    SELL_PENDING_APPROVAL = 'SELL_PENDING_APPROVAL',
    SELL_NEGOTIATION = 'SELL_NEGOTIATION',
    SELL_BUYING_PROMISSORY_AGREEMENT = 'SELL_BUYING_PROMISSORY_AGREEMENT',
    SELL_DEED = 'SELL_DEED',
    SELL_CANCELED = 'SELL_CANCELED',
    SELL_CLOSED = 'SELL_CLOSED',
}
export enum RentalWorkflowStateEnum {
    RENTAL_RAISING = 'RENTAL_RAISING',
    RENTAL_DOCUMENTS_APPROVAL = 'RENTAL_DOCUMENTS_APPROVAL',
    RENTAL_PENDING_APPROVAL = 'RENTAL_PENDING_APPROVAL',
    RENTAL_LISTED = 'RENTAL_LISTED',
    RENTAL_NEGOTIATION = 'RENTAL_NEGOTIATION',
    RENTAL_PROPOSAL_APPROVED = 'RENTAL_PROPOSAL_APPROVED',
    RENTAL_RENTED = 'RENTAL_RENTED',
    RENTAL_CANCELED = 'RENTAL_CANCELED',
}
export const WorkflowStateEnum = {
    ...SellWorkflowStateEnum,
    ...RentalWorkflowStateEnum,
} as const;

export type WorkflowStateEnumType = string;
export interface WorkflowState {
    transactionState: WorkflowStateEnumType;
    description: string;
}
export interface WorkflowCurrentStateSteps {
    currentState: WorkflowStateEnumType;
    nextState: WorkflowStateEnumType;
    previousState: WorkflowStateEnumType;
}
export interface WorkflowStatePayload {
    state: WorkflowStateEnumType;
    notes?: string;
}
export interface WorkflowAssignmentResponse {
    missingDocs: number;
    transactionAssignments: WorkflowAssignment[];
}

export interface WorkflowAssignment {
    documentTypeId: number;
    documentTypeCode: string;
    documentTypeName: string;
    assignment: DocumentChecklistAssignment;
    participantRole: WorkflowParticipantRoleEnum;
    participantId: string;
    participantName: string;
    documentId: number;
    documentName: string;
    status: DocumentStatus;
}

export interface ParticipantAssignPinValidationPayload {
    authenticationPIN: string;
}

export enum DocumentChecklistAssignment {
    UPLOAD = 'UPLOAD',
    REVIEW = 'REVIEW',
}

export interface WorkflowDocument {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    organizationId: number;
    name: string;
    documentType: string;
    documentTypeCode: string;
    externalId: string;
    documentStatus: DocumentStatus;
    documentNotes: string;
    transactionId: number;
    lastUserInteractionName: string;
    lastUserInteractionId: string;
    downloadUrl: string;
    owner: PublicUserDTO;
}

export enum DocumentStatus {
    AWAITING_APPROVAL = 'AWAITING_APPROVAL',
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}

export interface WorkflowDocumentUploadPayload {
    file: File;
    documentType: number;
    name: string;
    userId?: string;
    notes?: string;
}

export interface WorkflowDocumentType {
    id: number;
    createdDate: string;
    lastModified: string;
    name: string;
    country: string;
    organizationId: number;
    code: string | null;
}

export interface WorkflowsFilterParams {
    _cursor?: string;
    _limit?: string;
    _sort?: string;
    email?: string;
    externalId?: string;
    from?: string;
    to?: string;
    transactionType?: WorkflowType;
    transactionStatus?: WorkflowStateEnumType;
    organizationMemberIds?: string[];
}

export enum ResourceType {
    NONE = 'NONE',
    CONTRACT = 'CONTRACT',
    TRANSACTION = 'TRANSACTION',
    DOCUMENT = 'DOCUMENT',
}

export enum ResourcePermissionsType {
    NONE = 'NONE',
    VIEW = 'VIEW',
    MANAGE = 'MANAGE',
    SIGNING = 'SIGNING',
    REVIEW = 'REVIEW',
}
export enum DocumentAccessPermissionsType {
    NONE = 'NONE',
    VIEW = 'VIEW'
}
export interface ResourcePermission {
    id: number;
    permissions: ResourcePermissionsType[];
}

export enum WorkflowResourceRequestStatus {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
}

export interface WorkflowResourceRequest {
    id: number;
    createDate: string;
    lastModifiedDate: string;
    transactionId: number;
    participantId: number;
    transactionTitle: string;
    participantName: string;
    resourceName: string;
    resourceId: number;
    requestToken: string;
    requestExpiresAt: string;
    resourceType: ResourceType;
    permissionType: ResourcePermissionsType;
    status: WorkflowResourceRequestStatus;
}

export interface WorkflowResourceRequestAccess {
    resourceType: ResourceType;
    permissionType: ResourcePermissionsType;
}

export enum ParticipantType {
    EXTERNAL = 'EXTERNAL',
    INTERNAL = 'INTERNAL'
}

// Transaction History
export enum TransactionEventType {
    PROPERTY_UPDATED = 'PROPERTY_UPDATED',
    TRANSACTION_CREATED = 'TRANSACTION_CREATED',
    TRANSACTION_UPDATED = 'TRANSACTION_UPDATED',
    TRANSACTION_DELETED = 'TRANSACTION_DELETED',
    TRANSACTION_STATE_CHANGED = 'TRANSACTION_STATE_CHANGED',
    TRANSACTION_OWNER_CHANGED = 'TRANSACTION_OWNER_CHANGED',
    TRANSACTION_TRANSFERRED = 'TRANSACTION_TRANSFERRED',
    PARTICIPANT_ADDED = 'PARTICIPANT_ADDED',
    PARTICIPANT_REMOVED = 'PARTICIPANT_REMOVED',
    PARTICIPANT_ROLE_UPDATED = 'PARTICIPANT_ROLE_UPDATED',
    PARTICIPANT_PERMISSIONS_UPDATED = 'PARTICIPANT_PERMISSIONS_UPDATED',
    PARTICIPANT_INVITED = 'PARTICIPANT_INVITED',
    DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
    DOCUMENT_DELETED = 'DOCUMENT_DELETED',
    DOCUMENT_APPROVED = 'DOCUMENT_APPROVED',
    DOCUMENT_REJECTED_WITH_NOTES = 'DOCUMENT_REJECTED_WITH_NOTES',
    DOCUMENT_PENDING_WITH_NOTES = 'DOCUMENT_PENDING_WITH_NOTES',
    CONTRACT_UPLOADED = 'CONTRACT_UPLOADED',
    CONTRACT_DELETED = 'CONTRACT_DELETED',
    CONTRACT_ATTACHED = 'CONTRACT_ATTACHED',
    CONTRACT_SIGNED = 'CONTRACT_SIGNED',
    CONTRACT_REPLACED = 'CONTRACT_REPLACED',
    CONTRACT_VOIDED = 'CONTRACT_VOIDED',
    PHOTO_UPLOADED = 'PHOTO_UPLOADED',
    PHOTO_DELETED = 'PHOTO_DELETED',
    PROPOSAL_SUBMITTED = 'PROPOSAL_SUBMITTED',
    PROPOSAL_APPROVED = 'PROPOSAL_APPROVED',
    PROPOSAL_REJECTED_WITH_NOTES = 'PROPOSAL_REJECTED_WITH_NOTES',
    VISIT_REQUESTED = 'VISIT_REQUESTED',
    VISIT_ACCEPTED = 'VISIT_ACCEPTED',
    SIGNER_SIGNED='SIGNER_SIGNED'
}

export interface TransactionDetails {
    notes?: string;
    oldStatus?: SellWorkflowStateEnum | RentalWorkflowStateEnum;
    newStatus?: SellWorkflowStateEnum | RentalWorkflowStateEnum;
    oldOwner?: User;
    newOwner?: User;
    oldTransaction?: Workflow;
    newTransaction?: Workflow;
    oldDocument?: WorkflowDocument;
    newDocument?: WorkflowDocument ;
    oldParticipant?: Participant;
    newParticipant?: Participant;
    oldContract?: Contract;
    newContract?: Contract;
    oldPermissions?: ParticipantPermissions;
    newPermissions?: ParticipantPermissions;

}

export interface TransactionEvent {
    id: number;
    createdDate: string;
    transaction: Workflow;
    type: TransactionEventType;
    details: TransactionDetails;
    user: User;
    notes: string;
}
