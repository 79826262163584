import { FunctionComponent, useEffect, useState } from 'react';
import { PendingInvite } from '../../../types/general';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { PendingInvitesList } from '../PendingInvitesList';
import { SignerInvite } from '../../../types/contracts';
import { InviteTypeEnum } from '../../../types/user';

type BaseProps = ContractsContext;

const SignerPendingInvitesBase: FunctionComponent<BaseProps> = (props) => {
    const {
        getPendingInvites,
    } = props;

    const [invites, setInvites] = useState<PendingInvite[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cursor, setCursor] = useState('');
    const [totalInvites, setTotalInvites] = useState<number | undefined>();

    useEffect(() => {
        getInvitesList(true);
    }, []);

    const getInvitesList = async (reset?: boolean) => {
        setIsLoading(true);

        const contractsData = await getPendingInvites(
            {
                _cursor: reset ? '' : cursor,
                pendingAcceptance: true,

            },
        );

        const transformedContractsData = contractsData.results
            .filter((signerInvite) => signerInvite.contract !== null)
            .map((signerInvite: SignerInvite) => {
                return {
                    id: `${InviteTypeEnum.CONTRACT}-${signerInvite.id}`,
                    inviteType: InviteTypeEnum.CONTRACT,
                    inviteToken: signerInvite.inviteToken ?? '',
                    inviteExpiresAt: signerInvite.inviteExpiresAt,
                    resourceTitle: signerInvite.contract?.name ?? '',
                    resourceType: signerInvite.contract?.contractType.name ?? null,
                };
            });
        if (reset) {
            setInvites(transformedContractsData);
            if (contractsData.totalAmount) {
                setTotalInvites(contractsData.totalAmount);
            }
        } else {
            setInvites((prev) => [...prev, ...transformedContractsData]);
        }

        setCursor(contractsData.cursor);
        setIsLoading(false);
    };
    return (
        <>
            {invites.length > 1 && (
                <PendingInvitesList
                    pendingInvites={invites}
                    hasMore={!!cursor}
                    requestMore={getInvitesList}
                    isLoading={isLoading}
                    totalInvites={totalInvites}
                />
            )}
        </>
    );
};

export const SignerPendingInvites = withContractsContext(SignerPendingInvitesBase);
