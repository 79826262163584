/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, {
    FunctionComponent, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import {
    TranslationContext,
    withTranslationContext,
} from '../../controllers/TranslationContext';

import {
    ContractState, Signer,
} from '../../../types/contracts';
import { ReactComponent as ProfileIcon } from '../../../assets/images/profile.svg';
import { ReactComponent as VerticalDotsIcon } from '../../../assets/images/vertical-dots.svg';
import Modal from '../Modal';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { ButtonWithMenuList } from '../ButtonWithMenuList';
import { useContractContext } from './ContractContextProvider';
import { MenuListOptions } from '../../../types/general';
import { EditSignerDrawer } from './EditSignerDrawer';
import { ShareComponent } from '../ShareComponent';
import { ReactComponent as SendIcon } from '../../../assets/images/plane.svg';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { Permissions } from '../../../types/permissions';
import { getTimeLeft } from '../../../utils/date';
import { signerInvitationUrl } from '../../../services/contracts';

interface OwnProps extends TranslationContext, ContractsContext {
    signer: Signer;
}

const SignerListItemComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        signer,
        resendInviteSigner,
    } = props;

    const {
        contract,
        placeholderList,
        pendingPlaceholder,
        prepareToRemoveSigner,
        createPendingPlaceholder,
        prepareContract,
    } = useContractContext();
    
    const canManage = useUserHasPermission([Permissions.MANAGE_CONTRACT, Permissions.MANAGE_ORGANIZATION_CONTRACTS, Permissions.MANAGE_ALL_ORGANIZATION_CONTRACTS]);
    const [openModalDeleteConfirm, setOpenModalDeleteConfirm] = useState(false);
    const [isEditingSigner, setIsEditingSigner] = useState(false);
    
    const hasPlaceholderInList: boolean = useMemo(() => pendingPlaceholder?.signer.id === signer.id || !!placeholderList.find((p) => p.signer.id === signer.id),
        [placeholderList, pendingPlaceholder]);
    const [isLoading, setIsLoading] = useState(false);

    const [externalOpen, setExternalOpen] = useState(false);

    const resendInvite = async () => {
        setIsLoading(true);
        const error = await resendInviteSigner(signer.contractId, signer.id);
        if (error) {
            toast.error(t('errors.expireInvite'));
        } else {
            setIsLoading(false);
            toast.success(t('signerStates.inviteExtended'));
        }
    };
    
    const signerOptions = (): MenuListOptions[] => {
        const options: MenuListOptions[] = [];
        
        if (signer.editable) {
            options.push({
                value: t('signersOptions.EDIT'),
                action: () => setIsEditingSigner(true),
                testId: 'edit-btn',
            });
        }

        if (contract?.contractState === ContractState.DRAFT) {
            if (!hasPlaceholderInList) {
                options.push({
                    value: t('signersOptions.ADD_PLACEHOLDER'),
                    action: () => createPendingPlaceholder(signer),
                    testId: 'add-placeholder-btn',
                });
            }

            options.push({
                value: t('signersOptions.DELETE'),
                action: () => setOpenModalDeleteConfirm(true),
                testId: 'delete-btn',
            });
        }

        if (isInviteExpired()) {
            options.push({
                value: t('signersOptions.RENEW_INVITE'),
                action: () => {
                    setExternalOpen(true);
                    resendInvite();
                    if (contract) {
                        prepareContract(contract?.id.toString());
                    }
                },
                testId: 'resend-btn',
            });
        }
        return options;
    };

    /**
     * Delete Signer
     *
     * @remarks
     * Initiates a DELETE request to remove this signer
     */
    const handleDeleteConfirm = async () => {
        await prepareToRemoveSigner(signer.id, onDeleteSuccess, onDeleteFailure);
    };

    /**
     * onSuccess callback function
     *
     * @remarks
     * Update state (and other tasks if needed) after sucessful delete response
     */
    const onDeleteSuccess = () => {
        setOpenModalDeleteConfirm(false);
        toast.success(t('contractCreate.deleteSuccess'));
    };

    /**
     * onFailure callback function
     *
     * @remarks
     * Update state and show toast with generic failure message
     */
    const onDeleteFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };
      
    const renderSignerState = () => {
        if (!hasPlaceholderInList) {
            return (
                <span className="form__fields__signer-line__info__icon-wrap__no-placeholder">
                    {t('signerStates.doesNotHavePlaceholder')}
                </span>
            );
        }

        if (!signer.signerUserId) {
            const { days, hours, minutes } = getTimeLeft(signer.inviteExpiresAt);
        
            if (days > 0 && hours === 0) {
                return (
                    <span className="form__fields__signer-line__info__icon-wrap__not-signed">
                        {t('signerStates.inviteSend')} | {t('signerStates.validFor')} {days} {t(days === 1 ? 'date.day' : 'date.days')}
                    </span>
                );
            }
        
            if (days > 0 && hours > 0) {
                return (
                    <span className="form__fields__signer-line__info__icon-wrap__not-signed">
                        {t('signerStates.inviteSend')} | {t('signerStates.validFor')} {days} {t(days === 1 ? 'date.day' : 'date.days')} {hours} {t(hours === 1 ? 'date.hour' : 'date.hours')}
                    </span>
                );
            }
        
            if (days === 0 && hours > 1 && minutes === 0) {
                return (
                    <span className="form__fields__signer-line__info__icon-wrap__not-signed">
                        {t('signerStates.inviteSend')} | {t('signerStates.validFor')} {hours} {t('date.hours')}
                    </span>
                );
            }
        
            if (days === 0 && hours > 0 && minutes > 0) {
                return (
                    <span className="form__fields__signer-line__info__icon-wrap__not-signed">
                        {t('signerStates.inviteSend')} | {t('signerStates.validFor')} {hours} {t(hours === 1 ? 'date.hour' : 'date.hours')} {minutes} {t(minutes === 1 ? 'date.minute' : 'date.minutes')}
                    </span>
                );
            }
        
            if (days === 0 && hours === 1 && minutes === 0) {
                return (
                    <div className="form__fields__signer-line__alert">
                        <span>{t('signerStates.inviteValid')} {hours} {t('date.hour')}</span>
                    </div>
                );
            }
        
            if (days === 0 && hours < 1 && minutes > 0) {
                return (
                    <div className="form__fields__signer-line__alert">
                        <span>{t('signerStates.inviteValid')} {minutes} {t(minutes === 1 ? 'date.minute' : 'date.minutes')}</span>
                    </div>
                );
            }
        
            return (
                <div className="form__fields__signer-line__alert">
                    <span>{t('signerStates.inviteExpired')}</span>
                </div>
            );
        }

        if (signer.signedAt) {
            return (
                <span className="form__fields__signer-line__info__icon-wrap__signed">
                    {t('signerStates.signed')}
                </span>
            );
        }

        return (
            <span className="form__fields__signer-line__info__icon-wrap__not-signed">
                {t('signerStates.notSigned')}
            </span>
        );
    };

    const isInviteExpired = () => {
        if (!signer.signerUserId) {
            const { days, hours, minutes } = getTimeLeft(signer.inviteExpiresAt);

            if (days <= 0 && hours <= 0 && minutes <= 0) {
                return true;
            }
        }
        return false;
    };
    return (
        <>
            <div className="form__fields__signer-line" key={signer.id}>
                <div className="form__fields__signer-line__info">
                    <div className="form__fields__signer-line__info__icon-wrap">
                        <ProfileIcon />
                    </div>
                    <div className="form__fields__signer-line__info__icon-wrap__typography">
                        <p className="form__fields__signer-line__info__icon-wrap__name">{signer.name}</p>
                        <div data-testid="signer-status">{renderSignerState()}</div>
                    </div>
                </div>
                {canManage && (
                    <div className="form__fields__signer-line__actions">
                        {signer.inviteToken && (
                            <ShareComponent
                                shareTitle={isInviteExpired() ? t('signersOptions.SHARE_NEW_INVITE') : t('signerActions.shareTitle')}
                                text={t('signerActions.shareText', { contractType: contract?.contractType.name })}
                                url={signerInvitationUrl(signer.inviteToken)}
                                subject={t('signerActions.emailSubject', { contractType: contract?.contractType.name })}
                                customIcon={<SendIcon />}
                                email={signer.inviteEmail}
                                {...(isInviteExpired() && {
                                    isLoading,
                                    handlerOnClick: resendInvite,
                                    customTooltip: t('signersOptions.RENEW_INVITE'),
                                    externalOpen,
                                    setExternalOpen,
                                })}
                              
                            />
                        )
                    }
                        <div className="form__fields__signer-line__actions__icon-wrap">
                            <ButtonWithMenuList
                                options={signerOptions()}
                                icon={<VerticalDotsIcon />}
                                data-testid="options-icon"
                            />
                        </div>
                    </div>
                )}
            </div>
            {/* DELETE MODAL */}
            <Modal
                open={openModalDeleteConfirm}
                title={t('contractCreate.deleteConfirmTitle')}
                handleClose={() => setOpenModalDeleteConfirm(false)}
                handleConfirm={handleDeleteConfirm}
            >
                <p>{t('contractCreate.deleteConfirmText')}</p>
            </Modal>
            <EditSignerDrawer
                isOpen={isEditingSigner}
                signerToEdit={signer}
                onClose={() => setIsEditingSigner(false)}
            />
        </>
    );
};

export const SignerListItem = withTranslationContext(withContractsContext(SignerListItemComponent));
