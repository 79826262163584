/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SlicerName } from './types';
import { User } from '../types/user';
import { Wallet } from '../types/billing';

export interface UserState {
    userWallet: Wallet | null;
    user: User | null;
}

const userInitialState: UserState = {
    userWallet: null,
    user: null,
};

const userSlice = createSlice({
    name: SlicerName.User,
    initialState: userInitialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserState['user']>) => {
            state.user = action.payload;
        },
        setUserWallet: (state, action: PayloadAction<UserState['userWallet']>) => {
            state.userWallet = action.payload;
        },
    },
});

export const {
    setUser, setUserWallet,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
