/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 *
 */

import { ReactNode } from 'react';
import { ProductFruitsManager } from './ProductFruits';

interface TrackerWrapperProps {
    children: ReactNode;
}

export const TrackerWrapper = (props: TrackerWrapperProps) => {
    const { children } = props;
    return (
        <ProductFruitsManager>
            {children}
        </ProductFruitsManager>
    );
};
