import { ProductFruits } from 'react-product-fruits';
import { ReactNode, useEffect, useState } from 'react';
import { useAppSelector } from '../utils/storeHooks';
import { PRODUCT_FRUITS_TOKEN } from '../settings';

interface ProductFruitsProps {
    children: ReactNode;
}

export const ProductFruitsManager = (prop: ProductFruitsProps) => {
    const { children } = prop;
    const userSlice = useAppSelector((state) => state.user);
    const orgSlice = useAppSelector((state) => state.organization);
    const preferencesSlice = useAppSelector((state) => state.preferences);

    const [isMounted, setIsMounted] = useState(true);

    const userInfo = {
        username: userSlice.user?.id.toString() ?? '',
        props: {
            organization: orgSlice.organizationSelected?.id.toString() ?? '',
            type: orgSlice.organizationSelected ? 'internal' : 'external',
        },
    };
    const handleLanguageChange = () => {
        // Destroy the current ProductFruits instance if it exists
        interface ProductFruitsWindow {
            productFruits?: {
                destroy?: () => void;
            };
        }
        const { productFruits } = (window as unknown as ProductFruitsWindow);
        if (productFruits?.destroy) {
            productFruits.destroy();
        }

        // Unmount the component
        setIsMounted(false);

        // Remount the component after a short delay
        setTimeout(() => {
            setIsMounted(true);
        }, 0);
    };

    useEffect(() => {
        // Trigger unmount and remount when the language changes
        handleLanguageChange();
    }, [preferencesSlice.language]);

    return (
        <div>
            {isMounted && PRODUCT_FRUITS_TOKEN && (
                <ProductFruits
                    workspaceCode={PRODUCT_FRUITS_TOKEN}
                    language={preferencesSlice.language}
                    user={userInfo}
                />
            )}
            {children}
        </div>
    );
};
