
import { FunctionComponent, useEffect, useState } from 'react';
import { PendingInvite } from '../../../types/general';
import { PendingInvitesList } from '../PendingInvitesList';
import { withWorkflowContext, WorkflowContext } from '../../controllers/WorkflowContext';
import { ParticipantInvite } from '../../../types/workflows';
import { InviteTypeEnum } from '../../../types/user';

type BaseProps = WorkflowContext;

const ParticipantPendingInvitesBase: FunctionComponent<BaseProps> = (props) => {
    const {
        getPendingInvites,
    } = props;

    const [invites, setInvites] = useState<PendingInvite[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cursor, setCursor] = useState('');
    const [totalInvites, setTotalInvites] = useState<number | undefined>();

    useEffect(() => {
        getInvitesList(true);
    }, []);

    const getInvitesList = async (reset?: boolean) => {
        setIsLoading(true);

        const participantInvites = await getPendingInvites({
            _cursor: reset ? '' : cursor,
            pendingAcceptance: true,
        });
        const transformedData = participantInvites.results
            .filter((participantInvite) => participantInvite.transaction !== null)
            .map((participantInvite: ParticipantInvite) => {
                return {
                    id: `${InviteTypeEnum.TRANSACTION}-${participantInvite.id}`,
                    inviteType: InviteTypeEnum.TRANSACTION,
                    inviteToken: participantInvite.inviteToken ?? '',
                    inviteExpiresAt: participantInvite.inviteExpiresAt,
                    resourceTitle: participantInvite.transaction?.title ?? '',
                    resourceType: participantInvite.transaction?.transactionType ?? null,
                };
            });
        if (reset) {
            setInvites(transformedData);
            if (participantInvites.totalAmount) {
                setTotalInvites(participantInvites.totalAmount);
            }
        } else {
            setInvites((prev) => [...prev, ...transformedData]);
        }

        setCursor(participantInvites.cursor);
        setIsLoading(false);
    };
    return (
        <>
            {invites.length > 1 && (
                <PendingInvitesList
                    pendingInvites={invites}
                    hasMore={!!cursor}
                    requestMore={getInvitesList}
                    isLoading={isLoading}
                    totalInvites={totalInvites}
                />
            )}
        </>
    );
};

export const ParticipantPendingInvites = withWorkflowContext(ParticipantPendingInvitesBase);
