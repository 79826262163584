/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export const VERSION = process.env.REACT_APP_COMMIT;
export const WEBAPP_URL = process.env.REACT_APP_WEBAPP_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
export const PUBLIC_EID_ENDPOINT = process.env.REACT_APP_PUBLIC_EID_ENDPOINT;
export const PRODUCT_FRUITS_TOKEN = process.env.REACT_APP_PRODUCT_FRUITS_TOKEN ?? '';
export const POSTHOG_KEY = process.env.REACT_APP_POSTHOG_KEY ?? '';
export const POSTHOG_HOST = process.env.REACT_APP_POSTHOG_HOST ?? '';
export const ANALYTICS_ENABLED = Boolean(process.env.REACT_APP_ANALYTICS_ENABLED) ?? false;
