
/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { FormValidationError, FormValidatorError, validateForm } from '../../utils/validations';
import { KeyedObject, ListResponse } from '../../types/general';
import {
    OrganizationAccess,
    OrganizationPayload,
} from '../../types/organizations';
import { OrganizationsContext, OrganizationsContextProvider } from './OrganizationsContext';
import {
    getUserOrganizationsAccessesUrl,
    organizationAccessRoles,
    organizationLogoUrl,
    organizationMembersUrl,
    organizationUrl,
    organizationsUrl,
} from '../../services/organizations';
import {
    loadMoreOrganizationAccesses,
    setOrganizationSelected,
} from '../../slicers/organizationSlice';
import { useAppDispatch, useAppSelector } from '../../utils/storeHooks';

import { ErrorResponse } from '../../types/errors';
import { Role } from '../../types/roles';
import { useAuthContext } from './AuthenticationContext';
import { useCornerstoneApi } from '../../api';
import { validations } from '../../constants/validations';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;

const OrganizationsController: FunctionComponent<Props> = (props: Props) => {
    const { children } = props;

    const dispatch = useAppDispatch();
    const organizationState = useAppSelector((state) => state.organization);
    const { user } = useAuthContext();
    const CornerstoneApiInstance = useCornerstoneApi();

    const validateCreateOrganizationForm = (fields: OrganizationPayload): FormValidationError | null => {
        const errors: KeyedObject<FormValidatorError[]> | null = validateForm(fields, validations.organizationCreate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    const submitNewOrganization: OrganizationsContext['submitNewOrganization'] = async (payload: OrganizationPayload) => {
        try {
            const { data } = await CornerstoneApiInstance.post(organizationsUrl(), payload);

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Submit organization logotype image
     *
     * @remarks
     * This is a POST API request to submit organization logotype image
     */
    const submitOrganizationLogo: OrganizationsContext['submitOrganizationLogo'] = async (organizationId, payload) => {
        try {
            await CornerstoneApiInstance.put(organizationLogoUrl(organizationId), payload);
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const getOrganization: OrganizationsContext['getOrganization'] = async (organizationId) => {
        try {
            const { data } = await CornerstoneApiInstance.get(organizationUrl(organizationId));
            return data;
        } catch {
            return null;
        }
    };

    const getUserOrganizations: OrganizationsContext['getUserOrganizations'] = async (cursor = '') => {
        if (!user?.id) {
            return {
                cursor: '',
                results: [],
            };
        }
        
        try {
            const params = {
                _cursor: cursor,
            };
            const { data } = await CornerstoneApiInstance.get<ListResponse<OrganizationAccess>>(getUserOrganizationsAccessesUrl(user.id, params), {
                headers: {
                    Accept: 'application/json',
                },
            });
            if (data?.results.length === 0) {
                return {
                    cursor: '',
                    results: [],
                };
            }

            return {
                cursor: data.cursor,
                results: data.results,
            };
        } catch {
            return {
                cursor: '',
                results: [],
            };
        }
    };
    const getOrganizationMembers: OrganizationsContext['getOrganizationMembers'] = async (organizationId: number, search = '') => {
        try {
            const params = {
                search,
            };
            const { data } = await CornerstoneApiInstance.get(organizationMembersUrl(organizationId, params));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const loadMoreUserOrganizations: OrganizationsContext['loadMoreUserOrganizations'] = (cursor) => {
        return getUserOrganizations(cursor).then((res) => {
            dispatch(loadMoreOrganizationAccesses(res));
        });
    };

    const selectOrganization: OrganizationsContext['selectOrganization'] = (payload: OrganizationAccess) => {
        dispatch(setOrganizationSelected(payload));
    };

    const getOrganizationAccessRoles: OrganizationsContext['getOrganizationAccessRoles'] = async (organizationAccess: OrganizationAccess) => {
        try {
            const { data: roles } = await CornerstoneApiInstance.get<Role[] | null>(
                organizationAccessRoles(organizationAccess.id),
            );
            return [roles, null];
        } catch (error) {
            return [null, error as ErrorResponse];
        }
    };

    return (
        <OrganizationsContextProvider
            value={{
                organizationAccessRoles: organizationState.organizationAccessRoles,
                organizationAccesses: organizationState.organizationAccesses,
                organizationSelected: organizationState.organizationSelected,
                submitNewOrganization,
                submitOrganizationLogo,
                validateCreateOrganizationForm,
                selectOrganization,
                getOrganization,
                getUserOrganizations,
                loadMoreUserOrganizations,
                getOrganizationAccessRoles,
                getOrganizationMembers,
            }}
        >
            {children}
        </OrganizationsContextProvider>
    );
};

export default OrganizationsController;
