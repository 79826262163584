/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

export const userByEmailUrl = (email: string) => {
    return `/users/email/?address=${email}`;
};
export const userInvitesUrl = (params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/users/invites${queryStr}`;
};
