/**
 *
 * @Copyright 2024 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ThemeProvider, createTheme } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from './store';

import { AuthenticationController } from './components/controllers/AuthenticationController';
import { MainRouterProvider } from './components/AppRouter';
import { materialThemeOptions } from './materialThemeOptions';
import { TrackerWrapper } from './trackers/TrackerWrapper';
import { AnalyticsWrapper } from './trackers/AnalyticsWrapper';

function App() {
    const theme = createTheme(materialThemeOptions);

    return (
        <ThemeProvider theme={theme}>
            <PersistGate persistor={persistor}>
                <Provider store={store}>
                    <AnalyticsWrapper>
                        <AuthenticationController>
                            <TrackerWrapper>
                                <MainRouterProvider />
                            </TrackerWrapper>
                        </AuthenticationController>
                    </AnalyticsWrapper>
                </Provider>
            </PersistGate>
        </ThemeProvider>
    );
}

export default App;
