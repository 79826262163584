/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

const GeneralAppRoute = {
    Index: '/',
    NotFound: '/not-found',
    PrivacyPolicy: '/privacy',
    Faq: '/faq',
    TermsAndConditions: '/terms-and-conditions',
    Settings: '/settings',
    NewSupportTicket: '/help/tickets/new',
    SupportTicketSubmitted: '/help/tickets/created',
};

const OrganizationRoutes = {
    OrganizationBillingDashboard: '/organization',
    OrganizationRoles: '/organization/roles',
    OrganizationRole: '/organization/roles/:roleId',
    OrganizationMembers: '/organization/members',
    OrganizationMemberInvitation: '/organization/invitations/:inviteToken',
    OrganizationPersonalProfile: '/organization/personal-profile',
    OrganizationAgencyProfile: '/organization/agency-profile',
    OrganizationChange: '/organization/change',
    OrganizationBillingMovements: '/organization/billing/movements',
    OrganizationBillingCreditMovements: '/organization/billing/movements/credits',
    OrganizationBillingDebitMovements: '/organization/billing/movements/debits',
    OrganizationBillingMovementsSubscription: '/organization/billing/movements/subscription',
};

const ContractsAppRoute = {
    SignerInvitation: '/invitation/signer/:signerToken',
    SignerInvitationClaimCallback: '/invitation/signer/:signerToken/claim-callback',
    Contracts: '/contracts',
    CreateContract: '/contracts/create',
    DraftContract: '/contracts/:contractId/draft',
    ContractCheckout: '/contracts/:contractId/checkout',
    ShowContract: '/contracts/:contractId',
    ContractsConfiguration: '/organization/contracts/configuration',
};

const SignaturesAppRoute = {
    SignatureAvailability: '/signature-availability',
    AesSignatureContext: '/contracts/:contractId/signers/:signerId/sign/aes/context',
    AesSignaturePhone: '/contracts/:contractId/signers/:signerId/sign/aes/phone',
    AesSignatureEmail: '/contracts/:contractId/signers/:signerId/sign/aes/email',
    CmdSignatureContext: '/contracts/:contractId/signers/:signerId/sign/cmd/context',
    CmdSignatureCredentials: '/contracts/:contractId/signers/:signerId/sign/cmd/credentials',
    CmdSignatureOtp: '/contracts/:contractId/signers/:signerId/sign/cmd/otp',
    QesSignatureTermsAndCondition: '/contracts/:contractId/signers/:signerId/qes/terms-and-conditions',
    QesSignaturePrivacyPolicy: '/contracts/:contractId/signers/:signerId/qes/privacy-policy',
    QesSignatureStartVideoValidation: '/contracts/:contractId/signers/:signerId/qes/start-video-validation',
    QesSignatureVideoValidationFailed: '/contracts/:contractId/signers/:signerId/qes/video-validation/failed',
    QesSignatureVideoValidationChecking: '/contracts/:contractId/signers/:signerId/qes/video-validation/checking',
    QesSignatureVideoValidationCheckingFailed: '/contracts/:contractId/signers/:signerId/qes/video-validation/checking/failed',
    QesSignatureOtp: '/contracts/:contractId/signers/:signerId/qes/otp',
};

const WorkflowsAppRoute = {
    Workflows: '/workflows',
    CreateWorkflowSelectType: '/workflows/create/select-type',
    CreateWorkflowInfo: '/workflows/create/type/:type',
    WorkflowInfo: '/workflows/:workflowId/step/info',
    WorkflowProperty: '/workflows/:workflowId/step/property',
    WorkflowParticipantsStep: '/workflows/:workflowId/step/participants',
    ViewWorkflow: '/workflows/:workflowId',
    CreateWorkflowContract: '/workflows/:workflowId/contracts/create',
    WorkflowContracts: '/workflows/:workflowId/contracts',
    WorkflowParticipants: '/workflows/:workflowId/participants',
    WorkflowMissingDocuments: '/workflows/:workflowId/missing-documents',
    WorkflowDocuments: '/workflows/:workflowId/documents',
    ViewWorkflowDocument: '/workflows/:workflowId/documents/:documentId',
    OrganizationWorkflows: '/organization/workflows',
    WorkflowParticipantAssign: '/transaction/:participantToken',
    WorkflowParticipantAssignPinValidation: '/transaction/:participantToken/pin-validation',
    WorkflowHistory: '/workflows/:workflowId/history/transactions',
};

const BillingRoutes = {
    BillingPlans: '/organization/billing/plans',
    BillingSelectOrganization: '/billing/select-organization',
    BillingCreateOrganization: '/billing/create-organization',
    BillingReviewPlan: '/billing/review-plan',
    BillingAddCredits: '/billing/add-credits',
    BillingInformation: '/billing/billing-information',
    PaymentMethods: '/billing/payment-methods',
    PaymentMethod: '/billing/payment-methods/:methodId',
    NewPaymentMethodSelector: '/billing/payment-methods/new',
    NewCreditCard: '/billing/payment-methods/new/card',
    NewCreditCardCallback: '/billing/payment-methods/new/card/callback',
    PaymentCheckout: '/billing/checkout',
    PaymentCheckoutComplete: '/billing/checkout/complete',
    OrganizationPurchaseCredits: '/organization/billing/purchase-credits',
    PurchaseCredits: '/billing/purchase-credits',
    PurchaseCreditsBillingInformation: '/billing/purchase-credits/billing-information',
    PurchaseCreditsCheckout: '/billing/purchase-credits/checkout',
    PurchaseCreditsCheckoutComplete: '/billing/purchase-credits/complete',
};
const AccountRoutes = {
    AccountWallet: '/account',
};

export const AppRoute = {
    ...GeneralAppRoute,
    ...OrganizationRoutes,
    ...ContractsAppRoute,
    ...SignaturesAppRoute,
    ...WorkflowsAppRoute,
    ...BillingRoutes,
    ...AccountRoutes,
} as const;

export type AppRouteType = string;
