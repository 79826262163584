/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash';
import { ReactComponent as EnterpriseIcon } from '../../../assets/images/building.svg';
import { ReactComponent as StarterIcon } from '../../../assets/images/businesses.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/checkmark.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/chevron-right.svg';
import { ReactComponent as ArrowUp } from '../../../assets/images/chevron-up.svg';
import { ReactComponent as TeamIcon } from '../../../assets/images/people.svg';
import { ReactComponent as IndividualIcon } from '../../../assets/images/user.svg';
import { ReactComponent as ProfessionalIcon } from '../../../assets/images/work-bag.svg';
import { demoLink, enterpriseSolutionLink, managementProcesses } from '../../../constants/billing';
import { AppRoute } from '../../../constants/routes';
import {
    PlanFeatureType, PlanSubtype, ProcessType, SubscriptionPlan,
} from '../../../types/billing';
import { ButtonVariant } from '../../../types/general';
import { getSubtypeOfSubscriptionPlan } from '../../../utils/billing';
import { formatNumberToLocale } from '../../../utils/number';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';

interface OwnProps extends TranslationContext, BillingContext {
    plan: SubscriptionPlan;
    isActive?: boolean;
    onClick?: (plan: SubscriptionPlan) => void;
}

const PlanCardBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        language,
        plan,
        isActive = false,
        onClick,
        selectPlanToPurchase,
    } = props;

    const navigate = useNavigate();
    const [isCondensed, setIsCondensed] = useState(true);

    const planSubtype = getSubtypeOfSubscriptionPlan(plan);

    const signatureItems = plan.planItems.filter((item) => item.featureType === PlanFeatureType.AES_SIGNATURE || item.featureType === PlanFeatureType.CMD_SIGNATURE || item.featureType === PlanFeatureType.QES_SIGNATURE);
    const transactionItem = plan.planItems.find((item) => item.featureType === PlanFeatureType.TRANSACTION);

    const handleSelectPlanClick = () => {
        selectPlanToPurchase(plan);
        if (onClick) {
            onClick(plan);
            return;
        }
        navigate(AppRoute.BillingSelectOrganization);
    };

    const renderPlanIcon = () => {
        switch (planSubtype) {
            case PlanSubtype.INDIVIDUAL:
                return <IndividualIcon />;
            case PlanSubtype.TEAM:
                return <TeamIcon />;
            case PlanSubtype.PROFESSIONAL:
                return <ProfessionalIcon />;
            case PlanSubtype.ENTERPRISE:
                return <EnterpriseIcon />;
            case PlanSubtype.STARTER:
            default:
                return <StarterIcon />;
        }
    };

    const renderPlanButton = () => {
        let buttonText = '';

        if (planSubtype === PlanSubtype.ENTERPRISE) {
            buttonText = t('plans.ENTERPRISE.contactUs');
        } else {
            buttonText = isActive ? t('plans.selectedPlan') : t('plans.selectPlan');
        }

        return (
            <Button
                name={`plan-${plan.id}`}
                testId={`button-plan-${plan.id}`}
                variant={ButtonVariant.Curved}
                extraClasses="full-width slim"
                onClick={planSubtype === PlanSubtype.ENTERPRISE ? () => { window.location.href = enterpriseSolutionLink; } : handleSelectPlanClick}
            >
                {buttonText}
            </Button>
        );
    };

    return (
        <div
            key={plan.id}
            className={
                classNames('plan-card',
                    { active: isActive })}
        >
            <div className="plan-card__icon">
                {renderPlanIcon()}
                {isActive && <span className="your-plan-badge">{t('plans.yourPlan')}</span>}
            </div>
            <h2 className="plan-card__title">
                {t(`plans.${planSubtype}.title`)}
            </h2>
            <p className="plan-card__description">
                {t(`plans.${planSubtype}.description`)}
            </p>
            <div className="plan-card__price">
                {planSubtype === PlanSubtype.STARTER && plan.annualPrice && transactionItem?.unitPrice && (
                    formatNumberToLocale(transactionItem.unitPrice.amount, language, plan.annualPrice.currency)
                )}
                {planSubtype === PlanSubtype.ENTERPRISE && (
                    t('plans.priceOnRequest')
                )}
                {planSubtype !== PlanSubtype.STARTER && planSubtype !== PlanSubtype.ENTERPRISE && isNumber(plan.monthlyPrice?.value) && (
                    <>
                        {formatNumberToLocale(plan.monthlyPrice.value, language, plan.monthlyPrice.currency)}
                        <span>
                            {t('plans.perMonth')}
                        </span>
                    </>
                )}
            </div>
            <div className="plan-card__price-description">
                {t(`plans.${planSubtype}.priceDescription`)}
            </div>
            {renderPlanButton()}
            {planSubtype === PlanSubtype.ENTERPRISE && (
                <Link to={demoLink}>{t('plans.askDemo')}</Link>
            )}
            <div className="separator" />
            {isCondensed && (
                <Button
                    testId="showMore-btn"
                    extraClasses="collapse-btn"
                    onClick={() => setIsCondensed(false)}
                >
                    {t('plans.seeMore')} <ArrowRight />
                </Button>
            )}
            {!isCondensed && (
                <div className="plan-card__details" data-testid="plan-card-details">
                    <div className="plan-card__details__workflows-limit">
                        {t(`plans.${planSubtype}.workflowLimit`)}
                    </div>
                    <div className="plan-card__details__accounts-limit">
                        {t(`plans.${planSubtype}.accountLimit`)}
                    </div>
                    <div className="plan-card__details__signatures">
                        <h3>{t('plans.signatures')}</h3>
                        {signatureItems.map((signature) => {
                            return (
                                <div key={signature.id} className="plan-card__details__signatures__signature">
                                    <div className="plan-card__details__signatures__signature__icon">
                                        <CheckIcon />
                                    </div>
                                    <div className="plan-card__details__signatures__signature__info">
                                        {t(`plans.${planSubtype}.signatures.${signature.featureType}.title`)}
                                        {signature.featureType !== PlanFeatureType.QES_SIGNATURE && signature.quota !== null && (
                                            <span>{t(`plans.${planSubtype}.signatures.${signature.featureType}.limit`)}</span>
                                        )}
                                        {signature.quota === null && (
                                            <span>{t('plans.customSignatureNumbers')}</span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="plan-card__details__processes">
                        <h3>{t('plans.managementProcesses')}</h3>
                        {planSubtype && managementProcesses[planSubtype].alreadyIncluded && <p>{t(`plans.${planSubtype}.managementProcesses.alreadyIncluded`)}</p>}
                        {planSubtype && Object.values(managementProcesses[planSubtype].processes).map((process) => {
                            return (
                                <div key={process} className="plan-card__details__processes__process">
                                    <div className="plan-card__details__processes__process__icon">
                                        <CheckIcon />
                                    </div>

                                    <div className="plan-card__details__processes__process__info">
                                        {t(`plans.${planSubtype}.managementProcesses.planIncluded.${process}.title`)}
                                        {(process === ProcessType.training || process === ProcessType.cloudIntegrations) && (
                                            <span>{t(`plans.${planSubtype}.managementProcesses.planIncluded.${process}.limit`)}</span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        testId="showLess-btn"
                        extraClasses="collapse-btn"
                        onClick={() => setIsCondensed(true)}
                    >
                        {t('plans.seeLess')}
                        <ArrowUp />
                    </Button>
                </div>
            )}
        </div>
    );
};

export const PlanCard = withBillingContext(withTranslationContext(PlanCardBase));
