/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { InviteParams } from '../types/members';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

export const membersUrl = (organizationId: number, params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/organizations/${organizationId}/members${queryStr}`;
};

export const organizationAccessMemberUrl = (memberId: number) => {
    return `/organization-access/${memberId}`;
};

export const memberRolesUrl = (memberId: number) => {
    return `/organization-access/${memberId}/roles`;
};

export const organizationInvitesUrl = (
    organizationId: number,
    params?: InviteParams,
) => {
    const queryStr = objectToParams(params as KeyedObject<string>);
    return `/organizations/${organizationId}/invites${queryStr}`;
};

export const invitesUrl = (params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/organization/invites${queryStr}`;
};

export const inviteUrl = (inviteToken: string) => {
    return `/organization/invites/${inviteToken}`;
};

export const inviteAcceptUrl = (inviteToken: string) => {
    return `/organization/invites/${inviteToken}/accept`;
};

export const inviteRejectUrl = (inviteToken: string) => {
    return `/organization/invites/${inviteToken}/reject`;
};

export const memberInvitationUrl = (inviteToken: string) => {
    return `${window.location.origin}/organization/invitations/${inviteToken}`;
};
