/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { ButtonVariant, OptionSelectField } from '../../../types/general';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { Alert } from '../Alert';
import { AppRoute } from '../../../constants/routes';
import { ReactComponent as BackIcon } from '../../../assets/images/arrow-left.svg';
import Button from '../Button';
import { FormSelectField } from '../FormSelectField';
import { Wallet } from '../../../types/billing';

interface OwnProps extends TranslationContext, OrganizationsContext, BillingContext {
    creditsCost: number;
    walletHasCredits: boolean;
    onConfirmPayment: (wallet: Wallet) => void;
}

const QuotaLimitExceededMessageComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        organizationSelected,
        creditsCost,
        walletHasCredits,
        getOrganizationWallet,
        getUsersWallet,
        onConfirmPayment,
    } = props;

    const [availableWalletsOptions, setAvailableWalletsOptions] = useState<OptionSelectField<number>[]>([]);
    const [availableWallets, setAvailableWallets] = useState<Wallet[]>([]);
    const [selectedWallet, setSelectedWallet] = useState<Wallet | undefined>();
    const [topUpWalletUrl, setTopUpWalletUrl] = useState<string>('');
    
    const navigate = useNavigate();

    useEffect(() => {
        requestAvailableUserWallets();
    }, []);

    const requestAvailableUserWallets = async () => {
        if (!organizationSelected) return;
        const walletsOptions: OptionSelectField<number>[] = [];
        const wallets: Wallet[] = [];

        const [walletData] = await getOrganizationWallet(String(organizationSelected.organization.id));
        if (walletData) {
            walletsOptions.push({
                value: walletData.id,
                label: t('workflowQuotaLimitExceeded.organizationWallet', { organizationName: organizationSelected.organization.name }),
            });
            wallets.push(walletData);
        }
        const [personalWalletData] = await getUsersWallet();
        if (personalWalletData) {
            walletsOptions.push({
                value: personalWalletData.id,
                label: t('workflowQuotaLimitExceeded.personalWallet'),
            });
            wallets.push(personalWalletData);
        }
        if (wallets.length === 0) {
            toast.error(t('errors.noneOrganization'));
            return;
        }

        setSelectedWallet(wallets[0]);
        setTopUpWalletUrl(wallets[0].organizationId !== null ? AppRoute.OrganizationPurchaseCredits : AppRoute.PurchaseCredits);
        setAvailableWallets(wallets);
        setAvailableWalletsOptions(walletsOptions);
    };

    const selectWallet = (walletId: number) => {
        const walletToSelect = availableWallets.find((wallet) => wallet.id === walletId);
        setSelectedWallet(walletToSelect);
        setTopUpWalletUrl(walletToSelect?.organizationId !== null ? AppRoute.OrganizationPurchaseCredits : AppRoute.PurchaseCredits);
    };

    return (
        <div className="quota-limit-exceeded-message" data-testid="quota-limit-exceeded-message">
            <div className="quota-limit-exceeded-message__header">
                <Button
                    onClick={() => navigate(AppRoute.OrganizationWorkflows)}
                    testId="back-btn"
                >
                    <BackIcon /> {t('general.goBack')}
                </Button>
                <h1>{t('workflowQuotaLimitExceeded.title')}</h1>
            </div>
            <p className="quota-limit-exceeded-message__description">{t('workflowQuotaLimitExceeded.description', { creditsAmount: creditsCost })}</p>
            <FormSelectField
                name="walletId"
                label={t('workflowQuotaLimitExceeded.selectWallet')}
                value={selectedWallet?.id}
                options={availableWalletsOptions}
                onChange={(_, value) => selectWallet(Number(value))}
                fullWidth
            />
            
            <ol className="quota-limit-exceeded-message__billing">
                <li className="quota-limit-exceeded-message__billing__header">
                    {t('workflowQuotaLimitExceeded.willUse')}
                </li>
                <li>
                    <span>{t('workflowQuotaLimitExceeded.oneWorkflow')}</span>
                    <span>{`${creditsCost} ${t('workflowQuotaLimitExceeded.credits')}`}</span>
                </li>
                <li className="quota-limit-exceeded-message__billing__total">
                    <span>{t('workflowQuotaLimitExceeded.total')}</span>
                    <span>{`${creditsCost} ${t('workflowQuotaLimitExceeded.credits')}`}</span>
                </li>
            </ol>
            {!walletHasCredits && (
                <>
                    <Alert
                        message={t('workflowQuotaLimitExceeded.noCredits')}
                        button={(
                            <Button
                                onClick={() => navigate(topUpWalletUrl)}
                                disabled={!topUpWalletUrl}
                            >
                                {t('workflowQuotaLimitExceeded.addCreditsBtn')}
                            </Button>
                        )}
                    />
                </>
            )}
            {selectedWallet && (
                <div className="btn-wrapper">
                    <Button
                        variant={ButtonVariant.Curved}
                        onClick={() => onConfirmPayment(selectedWallet)}
                        disabled={!walletHasCredits}
                        testId="confirm-btn"
                    >
                        {t('workflowQuotaLimitExceeded.confirmBtn')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export const QuotaLimitExceededMessage = withTranslationContext(withOrganizationsContext(withBillingContext(QuotaLimitExceededMessageComponent)));
