/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';
import { Contract } from '../../types/contracts';
import { DataOrError, ErrorResponse } from '../../types/errors';
import {
    KeyedObject, ListResponse, NoContentResponse,
} from '../../types/general';
import {
    CommissionType,
    DocumentChecklistAssignment,
    DocumentStatus,
    LocationEntity,
    MatrixArticleType,
    Participant,
    ParticipantAssignPinValidationPayload,
    ParticipantPayload,
    PropertyType,
    PropertyTypeEnum,
    PropertyTypology,
    PropertyTypologyEnum,
    Workflow,
    WorkflowAssignmentResponse,
    WorkflowCurrentStateSteps,
    WorkflowDocument,
    WorkflowDocumentType,
    WorkflowDocumentUploadPayload,
    WorkflowParticipantRoleEnum,
    WorkflowPayload,
    WorkflowProperty,
    WorkflowPropertyPayload,
    WorkflowResponseType,
    WorkflowState,
    WorkflowStateEnum,
    WorkflowStatePayload,
    WorkflowType,
    WorkflowsFilterParams,
    ResourceType,
    WorkflowResourceRequest,
    ResourcePermissionsType,
    WorkflowResourceRequestStatus,
    ParticipantPermissionsList,
    ParticipantPermissions,
    ParticipantPermissionsPayload,
    WorkflowFrequencyType,
    ParticipantMemberPayload,
    LocationPayload,
    WorkflowParticipantRole,
    ParticipantInvite,
    TransactionEvent,
} from '../../types/workflows';
import { FormValidationError } from '../../utils/validations';
import { WorkflowController } from './WorkflowController';
import { Permissions } from '../../types/permissions';

export interface WorkflowContext {
    getWorkflow: (workflowId: string) => Promise<DataOrError<Workflow, ErrorResponse>>;
    getWorkflowTypes: () => Promise<DataOrError<WorkflowResponseType[], ErrorResponse>>;
    getProperty: (workflowId: string) => Promise<DataOrError<WorkflowProperty, ErrorResponse>>;
    createWorkflow: (payload: WorkflowPayload) => Promise<DataOrError<Workflow, ErrorResponse>>;
    deleteWorkflow: (workflowId: string) => Promise<undefined | ErrorResponse>;
    validateWorkflow(fields: WorkflowPayload): FormValidationError | null;
    editWorkflow: (workflowId: string, payload: WorkflowPayload) => Promise<DataOrError<Workflow, ErrorResponse>>;
    createWorkflowProperty: (workflowId: string, payload: WorkflowPropertyPayload) => Promise<DataOrError<WorkflowProperty, ErrorResponse>>;
    editWorkflowProperty: (workflowId: string, payload: WorkflowPropertyPayload) => Promise<DataOrError<WorkflowProperty, ErrorResponse>>;
    validateCreateWorkflowProperty(fields: WorkflowPropertyPayload): FormValidationError | null;
    getWorkflowParticipants: (workflowId: string, cursor?: string, limit?: string) => Promise<DataOrError<ListResponse<Participant>, ErrorResponse>>;
    addNewParticipant: (workflowId: string, payload: ParticipantPayload) => Promise<DataOrError<Participant, ErrorResponse>>;
    addOrganizationMemberAsParticipant: (workflowId: string, payload: ParticipantMemberPayload) => Promise<DataOrError<Participant, ErrorResponse>>;
    assignUserToParticipant: (workflowToken: string) => Promise<DataOrError<Participant, ErrorResponse>>;
    loadWorkflowInformation: (participantToken: string) => Promise<DataOrError<Workflow, ErrorResponse>>;
    validateParticipantAssignPinCode: (workflowToken: string, payload: ParticipantAssignPinValidationPayload) => Promise<DataOrError<Participant, ErrorResponse>>;
    deleteParticipant: (workflowId: string, participantId: string) => Promise<undefined | ErrorResponse>;
    getParticipantRoleOptions: (workflowType: WorkflowType) => Promise<DataOrError<WorkflowParticipantRole[], ErrorResponse>>;
    uploadWorkflowDocument: (workflowId: string, payload: WorkflowDocumentUploadPayload) => Promise<DataOrError<WorkflowDocument, ErrorResponse>>;
    updateParticipantRole: (workflowId: string, participantId: string, role: WorkflowParticipantRoleEnum) => Promise<undefined | ErrorResponse>;
    transferOwnership: (workflowId: string, newOwnerId: string) => Promise<undefined | ErrorResponse>;
    getParticipantWorkflows: (filters: WorkflowsFilterParams) => Promise<DataOrError<ListResponse<Workflow>, ErrorResponse>>;
    getLocation: (params: LocationPayload) => Promise<DataOrError<LocationEntity[], ErrorResponse>>;
    getPropertyTypes: () => Promise<DataOrError<PropertyType[], ErrorResponse>>;
    getPropertyTypologies: (propertyType: PropertyTypeEnum) => Promise<DataOrError<PropertyTypology[], ErrorResponse>>;
    getWorkflowStates: (workflowType: string) => Promise<DataOrError<WorkflowState[], ErrorResponse>>;
    getWorkflowAvailableStates: (workflowId: string) => Promise<DataOrError<WorkflowCurrentStateSteps, ErrorResponse>>;
    changeWorkflowState(workflowId: string, payload: WorkflowStatePayload): Promise<undefined | ErrorResponse>;
    getWorkflowContracts: (workflowId: string, cursor?: string, limit?: string) => Promise<DataOrError<Contract[], ErrorResponse>>;
    getWorkflowAssignments: (workflowId: string, assignmentType: DocumentChecklistAssignment, missingAction?: boolean) => Promise<DataOrError<WorkflowAssignmentResponse, ErrorResponse>>;
    getDocuments: (workflowId: string, cursor?: string, limit?: string) => Promise<DataOrError<ListResponse<WorkflowDocument>, ErrorResponse>>;
    getDocumentTypes: (workflowId: string) => Promise<DataOrError<WorkflowDocumentType[], ErrorResponse>>;
    getDocument: (workflowId: string, documentId: string) => Promise<DataOrError<WorkflowDocument, ErrorResponse>>;
    getDocumentPdf: (workflowId: string, documentId: string) => Promise<DataOrError<Blob, ErrorResponse>>;
    deleteDocument: (workflowId: string, documentId: string) => Promise<undefined | ErrorResponse>;
    reviewDocument: (workflowId: string, documentId: string, documentStatus: DocumentStatus, notes: string) => Promise<undefined | ErrorResponse>;
    getOrganizationWorkflows: (organizationId: number, filters: WorkflowsFilterParams) => Promise<DataOrError<ListResponse<Workflow>, ErrorResponse>>;
    getResourceRequests: (workflowId: string, resourceId: string, resourceType: ResourceType, status: WorkflowResourceRequestStatus) => Promise<DataOrError<WorkflowResourceRequest[], ErrorResponse>>;
    updateResourceRequestPermission: (workflowId: string, resourceId: string, resourceType: ResourceType, participantId: number, permissionType: ResourcePermissionsType) => Promise<undefined | ErrorResponse>;
    getWorkflowParticipantPermissions: (workflowId: string) => Promise<DataOrError<ParticipantPermissionsList, ErrorResponse>>;
    reviewResourceRequest: (workflowId: string, requestedAccessUuid: string, status: WorkflowResourceRequestStatus) => Promise<undefined | ErrorResponse>;
    requestAccessToWorkflowResource: (workflowId: string, resourceId: string, resourceType: ResourceType) => Promise<DataOrError<NoContentResponse, ErrorResponse>>;
    getParticipantPermissions: (workflowId: string, participantId: string) => Promise<DataOrError<ParticipantPermissions, ErrorResponse>>;
    updateParticipantPermissions: (workflowId: string, participantId: string, payload: ParticipantPermissionsPayload) => Promise<undefined | ErrorResponse>;
    getTransactionEvents: (TransactionId: string, sort: string, cursor?: string, limit?: string,) => Promise<ListResponse<TransactionEvent>>;
    getPendingInvites(params: KeyedObject<string | boolean>): Promise<ListResponse<ParticipantInvite>>;
}

const propertyDefault = {
    id: 0,
    createdDate: '',
    lastModifiedDate: '',
    propertyType: PropertyTypeEnum.APARTMENT,
    typology: PropertyTypologyEnum.T0,
    address: '',
    district: { id: 0, name: '' },
    county: { id: 0, name: '' },
    parish: { id: 0, name: '' },
    zone: '',
    matrixType: MatrixArticleType.URBAN,
    matrixArticle: '',
    matrixFraction: '',
    uniqueId: '',
};
const workflowDefault: Workflow = {
    id: 0,
    title: '',
    description: '',
    transactionType: WorkflowType.SELL,
    value: {
        value: 1,
        currency: 'EUR',
    },
    commissionType: CommissionType.FIXED,
    commission: {
        value: 0,
        currency: 'EUR',
    },
    status: WorkflowStateEnum.SELL_CANCELED,
    frequencyType: WorkflowFrequencyType.NONE,
    expiresAt: '',
    externalId: '',
    userOwnerId: '',
    organizationId: 0,
    propertyId: 0,
    createdDate: '',
    lastModifiedDate: '',
    cmi: '',
    property: propertyDefault,
};
const participantDefault = {
    id: 0,
    createdDate: '',
    lastModifiedDate: '',
    transactionId: 0,
    inviteCreatorId: '',
    inviteEmail: '',
    inviteExpiresAt: '',
    name: '',
    participantRole: WorkflowParticipantRoleEnum.ADMIN,
    inviteToken: '1',
};
const workflowDocumentDefault: WorkflowDocument = {
    id: 0,
    createdDate: '',
    lastModifiedDate: '',
    organizationId: 0,
    name: '',
    documentType: '',
    documentTypeCode: '',
    externalId: '',
    documentStatus: DocumentStatus.PENDING,
    documentNotes: '',
    transactionId: 0,
    lastUserInteractionName: '',
    lastUserInteractionId: '',
    downloadUrl: '',
    owner: {
        id: '',
        firstName: '',
        lastName: '',
        fullName: '',
    },
};
const workflowAvailableStatesDefault = {
    currentState: WorkflowStateEnum.SELL_CANCELED,
    nextState: WorkflowStateEnum.SELL_CANCELED,
    previousState: WorkflowStateEnum.SELL_CANCELED,
};

const workflowResourceRequestDefault: WorkflowResourceRequest = {
    id: 1,
    createDate: '',
    lastModifiedDate: '',
    transactionId: 0,
    participantId: 0,
    transactionTitle: '',
    participantName: '',
    resourceName: '',
    resourceId: 0,
    requestToken: '',
    requestExpiresAt: '',
    resourceType: ResourceType.DOCUMENT,
    permissionType: ResourcePermissionsType.VIEW,
    status: WorkflowResourceRequestStatus.ACCEPTED,
};

const workflowParticipantPermissionsDefault: ParticipantPermissionsList = {
    participant: participantDefault,
    organizationPermissions: ['NONE'],
    transactionPermissions: [ResourcePermissionsType.VIEW],
    documents: [],
    contracts: [{
        id: 0,
        permissions: [ResourcePermissionsType.VIEW],
    }],
};

const participantPermissionsDefault: ParticipantPermissions = {
    role: WorkflowParticipantRoleEnum.ADMIN,
    transaction: [Permissions.NONE],
    contracts: [Permissions.NONE],
    documents: [Permissions.NONE],
};

export const workflowContextDefaultValue: WorkflowContext = {
    getWorkflow: async () => [workflowDefault, null],
    getWorkflowTypes: async () => [[], null],
    getProperty: async () => [propertyDefault, null],
    createWorkflow: async () => [workflowDefault, null],
    deleteWorkflow: async () => undefined,
    validateWorkflow: () => null,
    editWorkflow: async () => [workflowDefault, null],
    createWorkflowProperty: async () => [propertyDefault, null],
    editWorkflowProperty: async () => [propertyDefault, null],
    validateCreateWorkflowProperty: () => null,
    getWorkflowParticipants: async () => [{ cursor: '', results: [] }, null],
    addNewParticipant: async () => [participantDefault, null],
    addOrganizationMemberAsParticipant: async () => [participantDefault, null],
    assignUserToParticipant: async () => [participantDefault, null],
    loadWorkflowInformation: async () => [Object.create({}), null],
    validateParticipantAssignPinCode: async () => [participantDefault, null],
    deleteParticipant: async () => undefined,
    getParticipantRoleOptions: async () => [Object.create({}), null],
    updateParticipantRole: async () => undefined,
    transferOwnership: async () => undefined,
    getParticipantWorkflows: async () => [{ cursor: '', results: [] }, null],
    getOrganizationWorkflows: async () => [{ cursor: '', results: [] }, null],
    getLocation: async () => [[], null],
    getPropertyTypes: async () => [[], null],
    getPropertyTypologies: async () => [[], null],
    getWorkflowStates: async () => [[], null],
    getWorkflowAvailableStates: async () => [workflowAvailableStatesDefault, null],
    changeWorkflowState: async () => undefined,
    getWorkflowContracts: async () => [[], null],
    uploadWorkflowDocument: async () => [Object.create({}), null],
    getWorkflowAssignments: async () => [{ missingDocs: 0, transactionAssignments: [] }, null],
    getDocuments: async () => [{ cursor: '', results: [] }, null],
    getDocumentTypes: async () => [[], null],
    getDocument: async () => [workflowDocumentDefault, null],
    getDocumentPdf: async () => [new Blob(), null],
    deleteDocument: async () => undefined,
    reviewDocument: async () => undefined,
    getResourceRequests: async () => [[workflowResourceRequestDefault], null],
    updateResourceRequestPermission: async () => undefined,
    reviewResourceRequest: async () => undefined,
    getWorkflowParticipantPermissions: async () => [workflowParticipantPermissionsDefault, null],
    requestAccessToWorkflowResource: async () => [{ status: 999 }, null],
    getParticipantPermissions: async () => [participantPermissionsDefault, null],
    updateParticipantPermissions: async () => undefined,
    getPendingInvites: async () => ({ cursor: '', results: [] }),
    getTransactionEvents: async () => ({ cursor: '', results: [] }),
};

const workflowContextInstance = createContext<WorkflowContext | null>(workflowContextDefaultValue);

export const WorkflowContextProvider = workflowContextInstance.Provider;
export const WorkflowContextConsumer = workflowContextInstance.Consumer;

export const withWorkflowContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof WorkflowContext>> => (props) => (
    <WorkflowController>
        <WorkflowContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </WorkflowContextConsumer>
    </WorkflowController>
);
