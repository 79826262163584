/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MembersContext, withMembersContext } from '../controllers/MembersContext';
import { OrganizationsContext, withOrganizationsContext } from '../controllers/OrganizationsContext';
import { RolesContext, withRolesContext } from '../controllers/RolesContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';

import { AppRoute } from '../../constants/routes';
import Button from '../elements/Button';
import { ButtonVariant } from '../../types/general';
import { ReactComponent as Logo } from '../../assets/images/logo_vertical.svg';
import Modal from '../elements/Modal';
import { ReactComponent as StaffIcon } from '../../assets/images/staff.svg';
import { useAuthContext } from '../controllers/AuthenticationContext';

interface OwnProps extends TranslationContext, MembersContext, OrganizationsContext, RolesContext { }

const OrganizationMemberInvitationScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t, getInvite, acceptInvite, rejectInvite,
    } = props;
    const { inviteToken } = useParams();
    const navigate = useNavigate();
    const {
        isAuthenticated,
        redirectToLogin,
    } = useAuthContext();

    const [organizationName, setOrganizationName] = useState('');
    const [roleName, setRoleName] = useState('');
    const [openModalConfirm, setOpenModalConfirm] = useState(false);

    useEffect(() => {
        decodeInvite();
    }, []);

    const decodeInvite = async () => {
        const invite = await getInvite(String(inviteToken));

        if (invite) {
            setOrganizationName(invite.organizationName);
            setRoleName(invite.roleName);
        }
    };

    const onAcceptInvite = async () => {
        if (!inviteToken) return;
        const acceptError = await acceptInvite(inviteToken);

        if (acceptError) {
            onAcceptInviteFailure(acceptError.errors[0].getMessageTranslated(t));
        } else {
            onAcceptInviteSuccess();
        }
    };

    const onDeclineInvite = async () => {
        if (!inviteToken) return;
        const rejectError = await rejectInvite(inviteToken);

        if (rejectError) {
            onRejectInviteFailure(rejectError.errors[0].getMessageTranslated(t));
        } else {
            onRejectInviteSuccess();
        }
    };

    const onAcceptInviteSuccess = () => {
        toast.success(t('invite.acceptSuccess'));
        navigate(AppRoute.Index);
    };

    const onAcceptInviteFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    const onRejectInviteSuccess = () => {
        toast.success(t('invite.rejectSuccess'));
        navigate(AppRoute.Index);
    };

    const onRejectInviteFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };
    
    const handleLogin = () => {
        redirectToLogin();
    };

    return (
        <div className="invite-screen">
            <div className="invite-screen__logo">
                <Logo />
            </div>
            <div className="invite-screen__content">
                <StaffIcon />
                <h1 className="invite-screen__content__title">{t('invite.title')}</h1>
                <p id="invitation-message" data-testid="invitation-message" className="invite-screen__content__message">{t('invite.message', { organization: organizationName, role: roleName })}</p>
                <div className="invite-screen__content__buttons">
                    { isAuthenticated ? (
                        <>
                            <Button variant={ButtonVariant.Curved} extraClasses="secondary" onClick={() => setOpenModalConfirm(true)}>
                                {t('invite.decline')}
                            </Button>
                            <Button variant={ButtonVariant.Curved} extraClasses="primary" onClick={onAcceptInvite}>
                                {t('invite.accept')}
                            </Button>
                        </>
                    ) : (
                        <Button variant={ButtonVariant.Curved} onClick={handleLogin}>
                            {t('invite.login')}
                        </Button>
                    )}
                </div>
            </div>
            <Modal
                open={openModalConfirm}
                title={t('invite.rejectConfirmTitle')}
                handleClose={() => setOpenModalConfirm(false)}
                handleConfirm={onDeclineInvite}
            >
                <p>{t('invite.rejectConfirmText')}</p>
            </Modal>
        </div>
    );
};

export const OrganizationMemberInvitationScreen = withTranslationContext(withMembersContext(withRolesContext(withOrganizationsContext(OrganizationMemberInvitationScreenBase))));
