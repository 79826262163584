/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export const addMonthsToDate = (months: number): Date => {
    const date = new Date();
    date.setMonth(date.getMonth() + months);

    date.setUTCHours(23, 59, 59, 0);

    return date;
};

export const formatToLocalDate = (date?: string, locale?: string) => {
    if (!date) return '';
    const event = new Date(date);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    
    const formattedDate = event.toLocaleDateString(locale ?? navigator.language, options);
  
    return formattedDate;
};

export const getTimeLeft = (expiryDate: string): { days: number; hours: number; minutes: number } => {
    const now = new Date(); // Current local time
    const expiry = new Date(expiryDate); // Parse the expiry date

    // Convert both dates to UTC
    const nowUTC = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
    );
    const expiryUTC = Date.UTC(
        expiry.getUTCFullYear(),
        expiry.getUTCMonth(),
        expiry.getUTCDate(),
        expiry.getUTCHours(),
        expiry.getUTCMinutes(),
        expiry.getUTCSeconds(),
    );

    // Calculate the difference in milliseconds
    const diff = expiryUTC - nowUTC;

    // If the expiry date is in the past, return 0 for all values
    if (diff < 0) {
        return { days: 0, hours: 0, minutes: 0 };
    }

    // Convert the difference to days, hours, and minutes
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return { days, hours, minutes };
};

// Format Date to be  DD/MM/YYYY - HH:mm UTC
export const formatDate = (isoDateString: string) => {
    const date = new Date(isoDateString);

    // Use UTC methods to ensure consistency across clients
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes} h`;
};

export const isBeforeToday = (date: string) => {
    const now = new Date();
    const dateToCompare = new Date(date);

    return dateToCompare.getTime() < now.getTime();
};
