/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FieldValidator } from './validators/Validator';
import { KeyedObject } from '../types/general';
import { TransactionEvent, Workflow } from '../types/workflows';
import { formatDate } from './date';
import { formatNumberToLocale } from './number';

export type FieldValue = string | number | boolean | unknown[];

export interface FormValidator {
    validations?: string[];
    func?: (fieldValue: FieldValue) => boolean;
    regex?: RegExp;
    length?: FormValidatorLength;
    min?: number;
    max?: number;
    decimalPoints?: number;
    size?: number;
}

export interface FormValidatorLength {
    lowerLimit: number;
    upperLimit: number;
}

export type FormValidatorError = {
    errorCode?: number;
    typeOfViolation?: string;
    size?: number;
    min?: number;
    max?: number;
    decimalPoints?: number;
}

export enum FormValidatorErrorType {
    NotBlank = 'NotBlank',
    NotEmpty = 'NotEmpty',
    NotDecimal = 'NotDecimal',
    SizeExact = 'SizeExact',
    Size = 'Size',
    SizeMin = 'SizeMin',
    SizeMax = 'SizeMax',
    Max = 'Max',
    Min = 'Min',
    Pattern = 'Pattern',
    Checked = 'Checked',
    PasswordsDontMatch = 'PasswordsDontMatch',
    NotNumber = 'NotNumber',
    DecimalPoints = 'DecimalPoints',
    FiscalCodeByCountry = 'FiscalCodeByCountry'
}

export enum ValidationType {
    NotBlank = 'NOT_BLANK',
    NotEmpty = 'NOT_EMPTY',
    NotDecimal = 'NOT_DECIMAL',
    Length = 'LENGTH',
    Max = 'MAX',
    Min = 'MIN',
    Regex = 'REGEX',
    Checked = 'CHECKED',
    Function = 'FUNCTION',
    Number = 'NUMBER',
    Decimal = 'DECIMAL',
    SizeExact = 'SIZE_EXACT',
}

export type FormValidationError = {
    fields: {
        [field: string]: FormValidatorError[];
    };
}

export const Regex = {
    Float: /^-?\d+(\.\d+)?$/,
    Decimal: /^-?\d+[.|,]?\d{0,2}$/,
    Email: /^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/,
    Coords: /^-?\d+[.]?\d{0,8}$/,
    PostalCode: /^[1-8]\d{3}-\d{3}$/,
    Swift: /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/,
    ExternalId: /^(?:[a-zA-Z0-9_-]+)?$/g,
    Language: /^([a-z]{2,3})/i,
};

export const validateField = (fieldName: string, fieldValue: FieldValue, validator: FormValidator) => {
    return FieldValidator.applyAllForValidations(fieldName, fieldValue, validator);
};

export const validateForm = (data: object, validations: KeyedObject<FormValidator>): KeyedObject<FormValidatorError[]> | null => {
    const errors: KeyedObject<FormValidatorError[]> = {};

    Object.entries(data).forEach((entry) => {
        const [field, fieldValue] = entry;
        
        if (field in validations) {
            if (errors) {
                if (validations[field]) {
                    const error = validateField(field, fieldValue, validations[field]);
                    if (error?.length) {
                        errors[field] = error;
                    }
                }
            }
        }
    });

    if (Object.keys(errors).length === 0) return null;
    return errors;
};

export const getErrorsForField = (field: string, errors?: FormValidationError|null): FormValidatorError[] => {
    return errors?.fields?.[field] ?? [];
};

export const comparePermissions = (
    event: TransactionEvent,
    t: (key: string, params?: object) => string,
): string => {
    const changes = [];
    if (event.details.oldPermissions && event.details.newPermissions) {
        // Compare contracts
        if (JSON.stringify(event.details.oldPermissions.contracts) !== JSON.stringify(event.details.newPermissions.contracts)) {
            changes.push(
                `${t('workflows.history.PARTICIPANT_PERMISSIONS_UPDATED', { participantName: event.user.fullName })} '${
                    t(`participantPermissions.${event.details.oldPermissions.contracts[0]}`)
                } ${t('workflows.history.contracts')}' ${
                    t('workflows.history.to')
                } '${t(`participantPermissions.${event.details.newPermissions.contracts[0]}`)} ${t('workflows.history.contracts')}'`,
            );
        }

        // Compare documents
        if (JSON.stringify(event.details.oldPermissions.documents) !== JSON.stringify(event.details.newPermissions.documents)) {
            changes.push(
                `${t('workflows.history.PARTICIPANT_PERMISSIONS_UPDATED', { participantName: event.user.fullName })} ${
                    t(`participantPermissions.${event.details.oldPermissions.documents[0]}`)
                } ${t('workflows.history.documents')}' ${
                    t('workflows.history.to')
                } '${
                    t(`participantPermissions.${event.details.newPermissions.documents[0]}`)} ${t('workflows.history.documents')}'`,
            );
        }

        // Compare transaction
        if (JSON.stringify(event.details.oldPermissions.transaction) !== JSON.stringify(event.details.newPermissions.transaction)) {
            changes.push(
                `${t('workflows.history.PARTICIPANT_PERMISSIONS_UPDATED', { participantName: event.user.fullName })} '${
                    t(`participantPermissions.${event.details.oldPermissions.transaction[0]}`)
                } ${t('workflows.history.workflows')}' ${
                    t('workflows.history.to')
                } '${
                    t(`participantPermissions.${event.details.newPermissions.transaction[0]}`)} ${t('workflows.history.workflows')}'`,
            );
        }
    }
    
    // Return the changes as a string
    return changes.join('\n');
};

export const compareTransactions = (
    oldTransaction: Workflow,
    newTransaction: Workflow,
    t: (key: string, params?: object) => string,
) => {
    const differences: { key: string; value: string }[] = [];

    const fieldsToCompare: (keyof Workflow | 'commission.value' | 'value.value')[] = [
        'cmi',
        'commission.value',
        'commissionType',
        'description',
        'expiresAt',
        'title',
        'transactionType',
        'value.value',
    ];

    function getNestedValue<T>(obj: T, path: string): unknown {
        return path.split('.').reduce<unknown>(
            (acc, key) => (acc && typeof acc === 'object' && key in acc ? (acc as Record<string, unknown>)[key] : undefined),
            obj,
        );
    }

    fieldsToCompare.forEach((field) => {
        const oldValue = getNestedValue(oldTransaction, field);
        const newValue = getNestedValue(newTransaction, field);

        if (oldValue !== newValue) {
            if (field === 'commission.value') {
                differences.push({
                    key: t('workflows.history.commission_value'),
                    value: `${newValue} %`,
                });
            } else if (field === 'expiresAt' && typeof newValue === 'string') {
                differences.push({
                    key: t('workflows.history.expiresAt'),
                    value: formatDate(newValue),
                });
            } else if (field === 'commissionType' && typeof newValue === 'string') {
                differences.push({
                    key: t('workflows.history.commissionType'),
                    value: t(`workflows.SELL.commissionTypes.${newValue}`),
                });
            } else if (field === 'value.value' && typeof newValue === 'number') {
                differences.push({
                    key: t('workflows.history.value_value'),
                    value: formatNumberToLocale(newValue, 'en', 'EUR'),
                });
            } else {
                differences.push({
                    key: t(`workflows.history.${field}`),
                    value: String(newValue),
                });
            }
        }
    });

    return differences;
};

export const compareProperties = (
    oldTransaction: Workflow,
    newTransaction: Workflow,
    t: (key: string, params?: object) => string,
) => {
    const differences: { key: string; value: string }[] = [];

    const fieldsToCompare: (keyof Workflow | 'property.address' | 'property.county.name' | 'property.district.name' | 'property.matrixArticle' | 'property.matrixFraction' | 'property.matrixType' | 'property.propertyType' | 'property.typology' | 'property.zone')[] = [
        'property.address',
        'property.county.name',
        'property.district.name',
        'property.matrixArticle',
        'property.matrixFraction',
        'property.matrixType',
        'property.propertyType',
        'property.typology',
        'property.zone',
    ];

    function getNestedValue<T>(obj: T, path: string): unknown {
        return path.split('.').reduce<unknown>(
            (acc, key) => (acc && typeof acc === 'object' && key in acc ? (acc as Record<string, unknown>)[key] : undefined),
            obj,
        );
    }

    fieldsToCompare.forEach((field) => {
        const oldValue = getNestedValue(oldTransaction, field);
        const newValue = getNestedValue(newTransaction, field);

        if (oldValue !== newValue) {
            if (field === 'property.propertyType' && typeof newValue === 'string') {
                differences.push({
                    key: t(`workflows.history.${field}`),
                    value: t(`propertyTypes.${newValue}`).replace('property.', ''),
                });
            } else {
                differences.push({
                    key: t(`workflows.history.${field}`),
                    value: String(newValue),
                });
            }
        }
    });

    return differences;
};
