import { FunctionComponent, useEffect, useState } from 'react';
import { PendingInvite } from '../../types/general';
import { PendingInvitesList } from './PendingInvitesList';
import { UserContext, withUserContext } from '../controllers/UserContext';
import { UserInvite } from '../../types/user';

type BaseProps = UserContext;

const HomePendingInvitesBase: FunctionComponent<BaseProps> = (props) => {
    const {
        getUserPendingInvites,
    } = props;

    const [invites, setInvites] = useState<PendingInvite[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cursor, setCursor] = useState('');

    useEffect(() => {
        getInvitesList(true);
    }, []);

    const getInvitesList = async (reset?: boolean) => {
        setIsLoading(true);
        const params = {
            _cursor: reset ? '' : cursor,
        };
        const invitesData = await getUserPendingInvites(params);

        const transformedInvitesData = invitesData.results
            .map((userInvite: UserInvite) => {
                return {
                    id: `${userInvite.inviteType}-${userInvite.id}`,
                    inviteType: userInvite.inviteType,
                    inviteToken: userInvite.inviteToken ?? '',
                    inviteExpiresAt: userInvite.expirationDate,
                    resourceTitle: userInvite.title ?? '',
                    resourceType: userInvite.type ?? null,
                };
            });
        if (reset) {
            setInvites(transformedInvitesData);
        } else {
            setInvites((prev) => [...prev, ...transformedInvitesData]);
        }

        setCursor(invitesData.cursor);
        setIsLoading(false);
    };
    return (
        <>
            {invites.length > 1 && (
                <PendingInvitesList
                    pendingInvites={invites}
                    hasMore={!!cursor}
                    requestMore={getInvitesList}
                    isLoading={isLoading}
                    moreElementPerScroll
                />
            )}
        </>
    );
};

export const HomePendingInvites = withUserContext(HomePendingInvitesBase);
