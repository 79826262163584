/*
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';
import { DataOrError, ErrorResponse } from '../../types/errors';

import UserController from './UserController';
import { RegisteredUserResponse, UserInvite } from '../../types/user';
import { KeyedObject, ListResponse } from '../../types/general';

export interface UserContext {
    getUserByEmail(email: string): Promise<DataOrError<RegisteredUserResponse, ErrorResponse>>;
    getUserPendingInvites(params?: KeyedObject<string>): Promise<ListResponse<UserInvite>>;
}

export const UserContextDefaultValue: UserContext = {
    getUserByEmail: async () => [Object.create({}), null],
    getUserPendingInvites: async () => ({ cursor: '', results: [] }),
};

const userContextInstance = createContext<UserContext | null>(UserContextDefaultValue);

export const UserContextProvider = userContextInstance.Provider;
export const UserContextConsumer = userContextInstance.Consumer;

export const withUserContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof UserContext>> => (props) => (
    <UserController>
        <UserContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </UserContextConsumer>
    </UserController>
);
