/**
 *
 * @Copyright 2024 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export interface User {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    fullName: string;
    contactEmail: string;
}

export interface RegisteredUserResponse {
    data: User;
    status: number;
}

export interface PublicUserDTO {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
}

export enum InviteTypeEnum {
    ORGANIZATION = 'ORGANIZATION',
    TRANSACTION = 'TRANSACTION',
    CONTRACT = 'CONTRACT'
}
export interface UserInvite {
    id: number;
    inviteType: InviteTypeEnum;
    createdDate: string;
    lastModifiedDate: string;
    expirationDate: string;
    inviteToken: string;
    authenticationPin: string | null;
    resourceId: number;
    type: string | null;
    title: string;
}
